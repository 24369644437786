export function getImageTitle(text: string): string {
  const parts = text?.split(`;`)

  if (parts && parts.length > 1) {
    return parts[1]
  }

  return text
}

export function getImageAlt(text: string): string {
  const parts = text?.split(`;`)

  if (parts && parts.length > 1) {
    return parts[0]
  }

  return text
}

import React, { FunctionComponent } from 'react'
import { WidgetBase } from 'Shared/components/builders/WidgetBuilder'
import WidgetContainer from 'Shared/components/builders/WidgetContainer'

import EasySoftwareEmbeddedCodeContainer, {
  EasySoftwareEmbeddedCodeContainerType,
} from '../EasySoftwareEmbeddedCode/EasySoftwareEmbeddedCodeContainer'

export interface WidgetEasySoftwareEmbeddedCodeContainerType
  extends WidgetBase,
  EasySoftwareEmbeddedCodeContainerType {
  contentType: 'EasySoftwareEmbeddedCodeContainer'
}

const WidgetEasySoftwareEmbeddedCodeContainer: FunctionComponent<WidgetEasySoftwareEmbeddedCodeContainerType> = (
  props
) => (
  <WidgetContainer
    {...props.puxWidgetProperties}
    {...props.puxWidgetAnimation}
    {...props.metadata}
  >
    <EasySoftwareEmbeddedCodeContainer
      embeddedCodeContainerItems={props.embeddedCodeContainerItems}
    />
  </WidgetContainer>
)

export default WidgetEasySoftwareEmbeddedCodeContainer

import './PuxNumbersContainer.scss'

import React, { FunctionComponent } from 'react'

import PuxNumber, { PuxNumberType } from './PuxNumber'

export type NumbersContainerLayout =
  | `NumbersContainer--four-layout`
  | `NumbersContainer--five-layout`
  | `NumbersContainer--six-layout`

export interface PuxNumbersContainerType {
  displayText: string
  numbersContainerLayout: NumbersContainerLayout
  numbersContainerShowProgress: boolean
  numberRadius: number
  numberStroke: number
  puxNumbersContainerItems: {
    contentItems: [PuxNumberType]
  }
  numbersContainerSelect: {
    cultureContentItems: [PuxNumberType]
  }
}

const PuxNumbersContainer: FunctionComponent<PuxNumbersContainerType> = (
  props
) => {
  return (
    <div className='NumbersContainer '>
      <div
        className={`NumbersContainer-grid ${props.numbersContainerLayout ?? `NumbersContainer--old-layout`
          }`}
      >
        {props.puxNumbersContainerItems?.contentItems?.map((item) => (
          <PuxNumber
            {...item}
            numberShowProgress={props.numbersContainerShowProgress}
            numberRadius={
              props.numbersContainerLayout == `NumbersContainer--four-layout`
                ? 116
                : 67.5
            }
            numberStroke={
              props.numbersContainerLayout == `NumbersContainer--four-layout`
                ? 8
                : 5
            }
            key={item.contentItemId}
          />
        ))}
        {props.numbersContainerSelect?.cultureContentItems?.map((item) => (
          <PuxNumber
            {...item}
            numberShowProgress={props.numbersContainerShowProgress}
            numberRadius={
              props.numbersContainerLayout == `NumbersContainer--four-layout`
                ? 116
                : 67.5
            }
            numberStroke={
              props.numbersContainerLayout == `NumbersContainer--four-layout`
                ? 8
                : 5
            }
            key={item.contentItemId}
          />
        ))}
      </div>
    </div>
  )
}

export default PuxNumbersContainer

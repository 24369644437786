import React, { FunctionComponent } from 'react'
import PuxMediaSelector, {
  PuxMediaSelectorType,
} from 'Root/Shared/components/content/mediaImage/PuxMediaSelector'
import { getLocalizedUrl } from 'Shared/utils/localeURL'
import useWindowDimensions from 'Shared/utils/useWindowDimensions'

import PuxCarousel from '../PuxCarousel/PuxCarousel'
import PuxLinkGalleryItem from './PuxLinkGalleryItem'
import PuxLinkGalleryWrapperType from './PuxLinkGalleryWrapper'

export interface PuxLinkGalleryItemType {
  puxMediaSelector: PuxMediaSelectorType
  linkGalleryItemUrl: {
    url: [string]
    internal: [boolean]
  }
}

export interface PuxLinkGallerySetType {
  contentItemId?: string
  displayText: string
  linkGallerySetItems: {
    contentItems: [PuxLinkGalleryItemType]
  }
  linkGalleryWrapperSlider: boolean
}

const PuxLinkGallerySet: FunctionComponent<PuxLinkGallerySetType> = (props) => {
  const CAROUSEL_MIN_ITEMS = 7
  const { width } = useWindowDimensions()
  const items = props.linkGallerySetItems.contentItems
  const useCarousel =
    items.length >= CAROUSEL_MIN_ITEMS &&
    props.linkGalleryWrapperSlider &&
    width > 992
  const slides = items.map((item, idx) => (
    <PuxLinkGalleryItem key={idx} {...item} />
  ))

  return (
    <div className='Gallery'>
      {useCarousel ? (
        <div className='Gallery-items'>
          <PuxCarousel
            contentItemId={props.contentItemId}
            slidesPerView={4}
            navigation
            autoplay
            slides={slides}
            breakpoints={{
              1400: {
                slidesPerView: 6,
              },
            }}
          />
        </div>
      ) : (
        <div className='Gallery-static'>
          <div className='Gallery-items'>{slides}</div>
        </div>
      )}
    </div>
  )
}

export default PuxLinkGallerySet

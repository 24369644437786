import React, { FunctionComponent } from 'react'

import PuxRating, { PuxRatingType } from '../PuxRating/PuxRating'
import PuxWysiwyg, { PuxWysiwygHtmlBodyType } from '../PuxWysiwyg/PuxWysiwyg'
import EasySoftwareEditorContainer from '../EasySoftwareEditorContainer/EasySoftwareEditorContainer'

export interface EasySoftwareReviewItemType {
  contentItemId: string
  contentType: string // unused
  displayText: string
  reviewContent: PuxWysiwygHtmlBodyType
  reviewReviewerCompany: PuxWysiwygHtmlBodyType
  reviewReviewerName: PuxWysiwygHtmlBodyType
  reviewReviewerPosition: PuxWysiwygHtmlBodyType
  reviewRatingList: {
    contentItems: [PuxRatingType]
  }
}

const EasySoftwareReviewItem: FunctionComponent<EasySoftwareReviewItemType> = (
  props
) => {
  return (
    <div className='ReviewItem'>
      <div className='ReviewItem-content'>
        <h5 className='ReviewItem-title'>{props.displayText}</h5>
        <div className='ReviewItem-text'>
          <PuxWysiwyg content={props.reviewContent} wysiwygId={props.contentItemId} />
        </div>
        <h4 className='ReviewItem-name'>{props.reviewReviewerName?.html}</h4>
        <div className='ReviewItem-position'>
          {props.reviewReviewerCompany?.html}
          {props.reviewReviewerPosition.html ? `, ${props.reviewReviewerPosition.html}` : ``}
        </div>
      </div>
      <div className='ReviewItem-ratings'>
        {props.reviewRatingList?.contentItems?.map((item, idx) => (
          <PuxRating {...item} key={idx} />
        ))}
      </div>
    </div>
  )
}

export default EasySoftwareReviewItem

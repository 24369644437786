import { ErrorMessage } from '@hookform/error-message'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { dt } from 'Root/Shared/utils/dynamicRS'

import { FormFieldTextareaType } from '../builders/FormBuilder'
import { WidgetProperitesPart } from '../builders/WidgetBuilder'
import FormErrorHelper from './FormErrorHelper'
import { getClass, getRequiredField } from './formFieldHelper'

const FormFieldTextareaWidget: React.FC<{
  props: FormFieldTextareaType
  widgetProperties?: WidgetProperitesPart
}> = ({ props, widgetProperties }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const formElement = props?.puxFormElement
  const formValidation = props?.puxFormElementValidation
  const formOptions = props?.puxFormElementOptions

  return (
    <div
      className={`FormGroup ${formOptions?.formElementOptionsCssClass ?? ``
        } ${getClass(
          props.metadata?.size,
          widgetProperties
        )} FormGroup--textarea`}
    >
      <label
        htmlFor={formElement?.formElementName}
        id={formElement?.formElementID}
      >
        {dt(`${formElement?.formElementLabel}`)}
        {getRequiredField(
          props?.puxFormElementValidation?.formElementValidationRequired
        )}
      </label>
      <Controller
        name={formElement?.formElementName}
        control={control}
        rules={{
          required: formValidation.formElementValidationRequired
            ? dt(`PuxForm.Error.Required`)
            : false,
        }}
        render={({ field }) => (
          <textarea
            {...field}
            placeholder={formElement?.formElementPlaceholder}
            autoFocus={formOptions?.formElementOptionsAutofocus}
            readOnly={formOptions?.formElementOptionsReadOnly}
            disabled={formOptions?.formElementOptionsDisabled}
            autoComplete={
              formOptions?.formElementOptionsAutocomplete ? `on` : `off`
            }
            rows={props.textareaRows}
            style={{ resize: props.textareaResize ? `vertical` : `none` }}
          />
        )}
      />
      <ErrorMessage
        render={(data) => <FormErrorHelper {...data} />}
        errors={errors}
        name={formElement?.formElementID ?? ``}
      />
    </div>
  )
}

export default FormFieldTextareaWidget

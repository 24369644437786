import './CaseStudyDetail.scss'
//@ts-ignore
import Layout from '@componentsfld/core/Layout'
import { HeadProps } from 'gatsby'
import React, { FunctionComponent } from 'react'
import EasySoftwareEditorContainer from 'Shared/components/EasySoftwareEditorContainer/EasySoftwareEditorContainer'
import WidgetBuilder from 'Shared/components/builders/WidgetBuilder'
import PuxMediaSelector from 'Shared/components/content/mediaImage/PuxMediaSelector'
import EasySoftwareBreadcrumbs from 'Shared/components/EasySoftwareBreadcrumbs/EasySoftwareBreadcrumbs'
import { getUrlWithProtocol } from 'Shared/utils/getUrlWithProtocol'
import { t } from 'ttag'
import PuxMetaTagsGatsby from 'Shared/utils/PuxMetaTagsGatsby'
import { GenericPageProps, IEasySoftwareCaseStudy } from 'Shared/queries/page-queries'
import { IPageContext } from 'Root/Shared/utils/data/buildTypedPage'

const CaseStudyDetail: FunctionComponent<GenericPageProps<IEasySoftwareCaseStudy>> = (props) => {
  const pageData = props.pageContext.pageData
  const breadcrumbsData = props.pageContext.breadcrumbs
  const headerData = props.pageContext.headerData
  const footerData = props.pageContext.footerData
  const widgets = props.pageContext.widgets
  const hasTopImage =
    pageData?.caseStudyTopImage?.mediaSelectorImage?.resizePaths?.length ?? 0 > 0
  const editorPath = `categoryWidgets,widgets`

  return (
    <Layout
      localizedPath={pageData.localization?.localizations}
      footerData={footerData}
      headerData={headerData}
      pageContentItemId={pageData.contentItemId}
    >
      <div className='CaseStudyDetail'>
        {!hasTopImage ? (
          <EasySoftwareBreadcrumbs items={breadcrumbsData} />
        ) : null}
        {hasTopImage ? (
          <div className='CaseStudyDetail-topimage'>
            <PuxMediaSelector {...pageData.caseStudyTopImage!} />
            <div className='CaseStudyDetail-title'>
              <h1>{pageData.displayText}</h1>
            </div>
          </div>
        ) : null}
        <div className='CaseStudyDetail-container'>
          {!hasTopImage && (
            <h1 className='CaseStudyDetail-title'>{pageData.displayText}</h1>
          )}
          <div className='CaseStudyDetail-meta'>
            <div className='CaseStudyDetail-image'>
              <PuxMediaSelector {...pageData.caseStudyImage} />
              {pageData.caseStudyUrl && (
                <div className='CaseStudyDetail-url'>
                  <a
                    href={getUrlWithProtocol(pageData.caseStudyUrl) || ``}
                    target='_blank'
                    rel='noopener'
                  >
                    {t`EasyRedmine.Website`}
                  </a>
                </div>
              )}
            </div>
            <div className='CaseStudyDetail-parameters'>
              {pageData.caseStudyParameters &&
                pageData.caseStudyParameters.contentItems &&
                pageData.caseStudyParameters.contentItems.map((item) => (
                  <div
                    className='CaseStudyDetail-parameter'
                    key={item.contentItemId}
                  >
                    <div className='CaseStudyDetail-label'>
                      {item.displayText}:
                    </div>
                    <div className='CaseStudyDetail-value'>
                      <EasySoftwareEditorContainer
                        pageContentItemId={item.contentItemId}
                        inline
                        content={item.keyValueContent}
                        editorPath={`caseStudyParameters,contentItems,cid-${item.contentItemId},puxKeyValue,keyValueContent,html`} // TODO KAJA
                        wysiwygId={item.contentItemId} />
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className='CaseStudyDetail-perex perex'>
            <EasySoftwareEditorContainer pageContentItemId={pageData.contentItemId} content={pageData.caseStudyPerex} editorPath={`caseStudyPerex,html`} wysiwygId={pageData.contentItemId + `-perex`} />
          </div>

          <div className='CaseStudyDetail-content'>
            <EasySoftwareEditorContainer pageContentItemId={pageData.contentItemId} content={pageData.caseStudyContent} editorPath={`caseStudyContent,html`} wysiwygId={pageData.contentItemId + `-content`} />
          </div>
        </div>
      </div>
      <div className='CaseStudyDetail-widgetContainer'>
        <WidgetBuilder pageContentItemId={pageData.contentItemId} widgetBuilderData={widgets?.caseStudyWidgets} editorPath={editorPath} />
      </div>
    </Layout>
  )
}

export default CaseStudyDetail

export function Head(props: HeadProps<never, IPageContext<IEasySoftwareCaseStudy>>) {
  const pageData = props.pageContext.pageData
  const metaTags = props.pageContext.metaTags

  return <PuxMetaTagsGatsby {...metaTags} localizedPaths={pageData.localization?.localizations} />
}

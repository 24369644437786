import './PuxNavigation.scss'

import PuxLink from 'Shared/utils/PuxLink'
import React, { FunctionComponent, ReactElement } from 'react'
import PuxIconBuilder from 'Shared/components/PuxIconBuilder/PuxIconBuilder'
import { KnowledgeBaseMenuItemType } from 'Shared/utils/getKnowledgeBaseData'
import { getHrefLang, getLocalizedUrl } from 'Shared/utils/localeURL'

export interface ContentLinkType {
  internal: boolean[]
  text: string
  url: string
}

export interface CustomContentLinkType extends ContentLinkType {
  children?: ContentLinkType[]
}

export interface SideCardNavigationLinkType {
  contentType?: string
  contentItemId?: string
  sideCardNavigationLinkItem: ContentLinkType
  sideCardNavigationLinkNewWindow: boolean
  sideCardNavigationLinkIcon: string
}

interface PuxNavigationType {
  type: 'widget'
  items: SideCardNavigationLinkType[]
}

interface KnowledgeBaseNavigationType {
  type: 'knowledgeBase'
  items: KnowledgeBaseMenuItemType[]
}

type NavigationType = PuxNavigationType | KnowledgeBaseNavigationType


const getNavigationComponent = (
  props: NavigationType
): ReactElement<null, ''> | null => {
  if (!props.items) {
    return null
  }

  switch (props.type) {
    case `widget`:
      return (
        <>
          {props.items.map((item, idx) => (
            <li className={`PuxNavigation-item`} key={idx}>
              {item.sideCardNavigationLinkItem.internal[0] && (
                <PuxLink
                  activeClassName={`is-active`}
                  className={`PuxNavigation-link`}
                  target={`${item.sideCardNavigationLinkNewWindow ? `_blank` : `_self`}`}
                  to={getLocalizedUrl(
                    Array.isArray(item.sideCardNavigationLinkItem?.url)
                      ? item.sideCardNavigationLinkItem?.url[0]
                      : item.sideCardNavigationLinkItem?.url
                  )}
                >
                  <span>{item.sideCardNavigationLinkItem?.text}</span>
                  <span className={item.sideCardNavigationLinkIcon}></span>
                </PuxLink>
              )}
              {!item.sideCardNavigationLinkItem.internal[0] && (
                <a
                  href={
                    Array.isArray(item.sideCardNavigationLinkItem?.url)
                      ? item.sideCardNavigationLinkItem?.url[0]
                      : item.sideCardNavigationLinkItem?.url
                  }
                  target={`
                    ${item.sideCardNavigationLinkNewWindow ? `_blank` : `_self`}
                    `}
                  className={`PuxNavigation-link`}
                >
                  <span>{item.sideCardNavigationLinkItem?.text}</span>
                  <span className={item.sideCardNavigationLinkIcon}></span>
                </a>
              )}
            </li>
          ))}
        </>
      )
    case `knowledgeBase`:
      return (
        <>
          {props.items.map((item, idx) => {
            const activeClass = item.isActive ? ` has-submenu` : ``

            return (
              <li className={`PuxNavigation-item${activeClass}`} key={idx}>
                {getKnowledgeBaseLinkItem(item)}
                {item.isActive && item.menuItemsList && (
                  <ul className={`PuxNavigation-submenu`}>
                    {item.menuItemsList.map((subItem, idxx) => (
                      <li className={`PuxNavigation-subitem`} key={idxx}>
                        {getKnowledgeBaseLinkItem(subItem)}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            )
          })}
        </>
      )

    default:
      return null
  }
}

const getKnowledgeBaseLinkItem = (
  linkItem: KnowledgeBaseMenuItemType
) => (
  <PuxLink
    activeClassName={`is-active`}
    className={`PuxNavigation-link`}
    to={getLocalizedUrl(linkItem.path)}
  >
    <span>{linkItem.displayText}</span>
  </PuxLink>
)


const PuxNavigation: FunctionComponent<NavigationType> = (props) => (
  <ul className='PuxNavigation'>{getNavigationComponent(props)}</ul>
)

export default PuxNavigation

import React, { FunctionComponent, useState } from 'react'

export interface DownloadIconType {
  icon: 'DownloadIcon'
  options?: DownloadIconPropsType
}

interface DownloadIconPropsType {
  className?: string
  color: DownloadIconStateType
  hoverColor: DownloadIconStateType
}

interface DownloadIconStateType {
  colorPrimary: string
}

const DownloadIcon: FunctionComponent<DownloadIconPropsType> = (props) => {
  const defaultTheme = props.color
  const hoverTheme = props.hoverColor
  const [colorTheme, setColorTheme] = useState<DownloadIconStateType>(
    defaultTheme
  )

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 20 20'
      width='100%'
      className={props.className?.toLowerCase()}
      onMouseEnter={() => setColorTheme(hoverTheme)}
      onMouseLeave={() => setColorTheme(defaultTheme)}
    >
      <g transform='matrix(-1, 0, 0, 1, 19.997854, 0)'>
        <path
          fill={colorTheme.colorPrimary}
          d='M5.29,12.71a1,1,0,0,1,1.42-1.42L9,13.59V1a1,1,0,0,1,2,0V13.59l2.29-2.3a1,1,0,0,1,1.42,1.42l-4,4a1,1,0,0,1-.33.21.94.94,0,0,1-.76,0,1,1,0,0,1-.33-.21ZM19,16a1,1,0,0,0-1,1,1,1,0,0,1-1,1H3a1,1,0,0,1-1-1,1,1,0,0,0-2,0,3,3,0,0,0,3,3H17a3,3,0,0,0,3-3A1,1,0,0,0,19,16Z'
        />
      </g>
    </svg>
  )
}

export default DownloadIcon

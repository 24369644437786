import './EasySoftwareWysiwygEditor.scss'

import React, { FunctionComponent, useEffect, useRef, useState } from 'react'

import EasySoftwareEditorBar from '../EasySoftwareEditorBar/EasySoftwareEditorBar'
import { PuxWysiwygHtmlBodyType } from '../PuxWysiwyg/PuxWysiwyg'

export interface EasySoftwareWysiwygEditorType {
  wysiwygId?: string
  className?: string
  inline?: boolean
  small?: boolean
  path?: string
  pageContentItemId?: string
  closeEditor: () => void
  saveEditor: (value: string, EditorRef: any, EditorButtonRef: any) => void
}

const EasySoftwareWysiwygEditor: FunctionComponent<EasySoftwareWysiwygEditorType> = (
  props
) => {
  const [editor, setEditor] = useState<any>()
  const [active, setActive] = useState<boolean>(false)
  const EditorButtonRef = useRef<HTMLButtonElement | null>(null)
  let numberOfChanges = 0
  const EditorRef = useRef<HTMLDivElement | null>(null)

  const classes = [`WysiwygEditor`]
  classes.push(props.inline ? `WysiwygEditor--inline` : `WysiwygEditor--spaced`)
  if (props.className) {
    classes.push(props.className)
  }
  if (props.small) {
    classes.push(`WysiwygEditor--small`)
  }

  useEffect(() => {
    initializeEditor()
  }, [])

  useEffect(() => {
    if (editor) {
      editor.focus()
    }
  }, [editor])

  function MakeNestedListIndented(list, type) {
    const li = document.createElement(`li`)
    if (type === `unordered`) {
      li.setAttribute(`data-list`, `bullet`)
    } else {
      li.setAttribute(`data-list`, `ordered`)
    }
    li.classList.add(`ql-indent-1`)
    li.innerHTML = list.querySelector(`li`).innerHTML
    list.parentNode?.insertBefore(li, list.nextSibling)
    list.remove()
  }

  async function editHtml() {  
    let content = await fetch(`${process.env.GATSBY_ORCHARD_API_URL}/puxapi/contentItem/getField?contentItemId=${props.pageContentItemId}&path=${props.path}`)
    .then((res) => {
      return res.text()
    })
    .catch((error) => {
      console.log(`Error saving editor data:`, error)
    })
    
    if (content) {
      let htmlBody = ``
      htmlBody = content.replace(
        / src="\/media\//g,
        ` src="${process.env.GATSBY_ORCHARD_API_URL}/media/`
      )

      htmlBody = htmlBody.replace(
        / href="\/media\//g,
        ` href="${process.env.GATSBY_ORCHARD_API_URL}/media/`
      )

      htmlBody = htmlBody
        .replace(/font-size:\s[0-9]{1,}rem[;]{0,1}/g, ``)
        .replace(/font-family: -apple-system([^">]*)&quot;/g, ``)
        .replace(/font-family: -apple-system([^">]*)&quot;/g, ``)
        .replace(/pux-button\s/g, `Button Button--large `)
        .replace(/pux-button-filled/g, `Button--primary`)
        .replace(/pux-button-outlined/g, `Button--secondary`)
        .replace(/pux-button-primary/g, `Button--yellow`)
        .replace(/pux-button-secondary/g, `Button--blue`)

      htmlBody = htmlBody.replace(
        /(<ul>|<ol>|<\/li>)(?:[\s]+)(<li>|<\/ul>|<\/ol>)/g,
        `$1$2`
      )

      const htmlBodyDiv = document.createElement(`div`)
      htmlBodyDiv.innerHTML = htmlBody

      // Format quill unordered lists

      htmlBodyDiv.querySelectorAll(`ul`).forEach((ul) => {
        ul.querySelectorAll(`li`).forEach((li) => {
          li.setAttribute(`data-list`, `bullet`)
        })
      })

      // Fix paragraph breaking list

      htmlBodyDiv.querySelectorAll(`li p`).forEach((p) => {
        const li = p.parentElement
        if (li) {
          li.innerHTML = p.innerHTML
          if (p.className) li.classList.add(p.className)
        }
      })

      // Replace html font with colored span

      htmlBodyDiv.querySelectorAll(`font`).forEach((font) => {
        let newSpan = document.createElement("span");
        newSpan.innerHTML = font.innerText;
        newSpan.style.color = font.getAttribute("color") ?? "";
        font.insertAdjacentElement("afterend", newSpan);
        font.remove()
      })

      // Prepare nested lists for Quill

      htmlBodyDiv.querySelectorAll(`ul ul`).forEach((list) => {
        MakeNestedListIndented(list, `unordered`)
      })
      htmlBodyDiv.querySelectorAll(`ol ol`).forEach((list) => {
        MakeNestedListIndented(list, `ordered`)
      })
      htmlBodyDiv.querySelectorAll(`ul ol`).forEach((list) => {
        MakeNestedListIndented(list, `ordered`)
      })
      htmlBodyDiv.querySelectorAll(`ol ul`).forEach((list) => {
        MakeNestedListIndented(list, `unordered`)
      })

      htmlBody = htmlBodyDiv.innerHTML
        .replace(/\n\n/g, ``)
        .replace(/<p><\/p>/g, `<p> </p>`)

      return htmlBody
    }
  }

  async function initializeEditor() {
    const { default: Quill } = await import(`quill`)

    const Parchment = Quill.import(`parchment`)
    const Block = Quill.import(`blots/block`)
    const BackgroundClass = Quill.import(`attributors/class/background`)
    const ColorClass = Quill.import(`attributors/class/color`)
    const SizeStyle = Quill.import(`attributors/style/size`)
    const TextAlignStyle = Quill.import(`attributors/style/align`)
    const Embed = Quill.import(`blots/embed`)
    const List = Quill.import(`formats/list`)
    const ListContainer = Quill.import(`formats/list`)

    const ClassBlockAttribute = new Parchment.Attributor(`class`, `class`, {
      scope: Parchment.Scope.BLOCK,
    })

    const ClassInlineAttribute = new Parchment.Attributor(`class`, `class`, {
      scope: Parchment.Scope.INLINE,
    })

    const DirBlockAttribute = new Parchment.Attributor(`dir`, `dir`, {
      scope: Parchment.Scope.BLOCK,
    })

    const DirInlineAttribute = new Parchment.Attributor(`dir`, `dir`, {
      scope: Parchment.Scope.INLINE,
    })

    Quill.register(BackgroundClass, true)
    Quill.register(ColorClass, true)
    Quill.register(SizeStyle, true)
    Quill.register(TextAlignStyle, true)

    Quill.register(ClassBlockAttribute)
    Quill.register(ClassInlineAttribute)

    Quill.register(DirBlockAttribute)
    Quill.register(DirInlineAttribute)

    /* Block */

    class BlockBlot extends Block {
      constructor(domNode) {
        super(domNode)
        domNode.setAttribute(`class`, ``)
        domNode.setAttribute(`dir`, ``)
        this.cache = {}
      }
    }

    BlockBlot.blotName = `block`

    class SoftLineBreakBlot extends Embed {
      static blotName = `softbreak`
      static tagName = `br`
    }

    Quill.register(SoftLineBreakBlot)

    /* Unordered list */

    class UListContainer extends ListContainer {}
    UListContainer.blotName = `ulist-container`
    UListContainer.tagName = `UL`
    UListContainer.className = `ul`

    class UListItem extends List {
      static register() {
        Quill.register(UListContainer)
      }

      static create(value: any) {
        const node = super.create()
        return node
      }
    }

    UListItem.blotName = `ulist`
    UListItem.tagName = `LI`
    UListItem.className = `LI`

    /* Ordered list */

    class OListContainer extends ListContainer {}
    OListContainer.blotName = `olist-container`
    OListContainer.tagName = `OL`
    OListContainer.className = `ol`

    class OListItem extends List {
      static register() {
        Quill.register(OListContainer)
      }

      static create(value: any) {
        const node = super.create()
        return node
      }
    }

    OListItem.blotName = `olist`
    OListItem.tagName = `LI`
    OListItem.className = `LI`

    UListItem.allowedChildren = [BlockBlot]
    OListItem.allowedChildren = [BlockBlot]
    UListContainer.allowedChildren = [
      UListItem,
      UListContainer,
      OListContainer,
      BlockBlot,
    ]
    OListContainer.allowedChildren = [
      OListItem,
      OListContainer,
      UListContainer,
      BlockBlot,
    ]

    Quill.register({
      'formats/list': List,
      'formats/ulist': UListItem,
      'formats/olist': OListItem,
    })

    /* Initialization */

    if (!editor || !document.querySelector(`#wysiwyg-${props.wysiwygId}`)) {
      const newEditor = new Quill(`#wysiwyg-${props.wysiwygId}`, {
        debug: `warn`,
        modules: {
          toolbar: {
            container: `#toolbar-${props.wysiwygId}`,
          },
          table: false,
        },
        placeholder: `Insert text..`,
        theme: `snow`
      })
      newEditor.on(`editor-change`, function (eventName, test, ...args) {
        if (eventName === `text-change`) {
          numberOfChanges += 1

          if (numberOfChanges > 1) {
            EditorButtonRef?.current?.classList.add(
              `WysiwygEditor-button--changed`
            )
          }
        }
      })

      newEditor.on(`selection-change`, function (range, oldRange, source) {
        if (range) {
          setActive(true)
        } else {
          setActive(false)
        }
      })

      const html = await editHtml()
      newEditor.root.innerHTML = html ?? ""

      setEditor(newEditor)
    }
  }

  async function saveData() {
    let result = editor.root.innerHTML.trim()
    const htmlResult = document.createElement(`div`)
    htmlResult.innerHTML = result

    // Change ordered list to unordered list

    htmlResult
      .querySelectorAll(`li[data-list='bullet'].ql-indent-1`)
      .forEach((li) => {
        const list = document.createElement(`ul`)
        li.parentNode?.insertBefore(list, li.nextSibling)
        li.classList.remove(`ql-indent-1`)
        list.innerHTML = li.outerHTML
        li.remove()
      })

    // Change indent to nested list

    htmlResult
      .querySelectorAll(`li[data-list='ordered'].ql-indent-1`)
      .forEach((li) => {
        const list = document.createElement(`ol`)
        li.parentNode?.insertBefore(list, li.nextSibling)
        li.classList.remove(`ql-indent-1`)
        list.innerHTML = li.outerHTML
        li.remove()
      })
    htmlResult.querySelectorAll(`li[data-list='bullet']`).forEach((li) => {
      const ol = li.parentElement
      if (ol?.nodeName === `OL`) {
        const ul = document.createElement(`ul`)
        ul.innerHTML = ol.innerHTML
        ol.parentElement?.replaceChild(ul, ol)
      }
    })

    // Save editor data

    result = htmlResult.innerHTML
    await props.saveEditor(result, EditorRef, EditorButtonRef)
  }

  return (
    <div ref={EditorRef} className={classes.join(` `)}>
      <EasySoftwareEditorBar
        wysiwygOptions={{
          saveHandler: saveData,
          closeHandler: props.closeEditor,
          saveButtonRef: EditorButtonRef,
          id: `toolbar-${props.wysiwygId}`,
          active: active,
        }}
      />
      <div id={`wysiwyg-${props.wysiwygId}`} className='Wysiwyg' />
    </div>
  )
}

export default EasySoftwareWysiwygEditor

import React, { useEffect, useState } from "react";
import { useLottie } from 'lottie-react';
import { useInView } from 'react-intersection-observer';
import PuxMediaSelector from "../mediaImage/PuxMediaSelector";
import { MediaSelectorImageType } from "../mediaImage/PuxPicture";
import useWindowDimensions from "Root/Shared/utils/useWindowDimensions";

type EasySoftwareLottieProps = {
  animationData: string,
  animationDataMobile: string | null,
  lottieImage: MediaSelectorImageType | null,
  externalPlaySignal?: boolean,
}

function EasySoftwareLottie({ animationData, animationDataMobile, lottieImage, externalPlaySignal }: EasySoftwareLottieProps) {
  const [isPlaying, setPlaying] = useState(false);
  const [ref, inView] = useInView({
    threshold: 0.6,
    triggerOnce: true,
  });

  const size = useWindowDimensions();

  const lottieAnimation = useLottie({
    animationData: size.width < 992 && animationDataMobile ? animationDataMobile : animationData,
    loop: false,
    autoplay: false,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  })

  useEffect(() => {
    if (externalPlaySignal && inView) {
      lottieAnimation.play()
    }
  }, [externalPlaySignal]);

  useEffect(() => {
    if (inView) {
      setPlaying(true);
    }
  }, [inView]);

  useEffect(() => {
    if (isPlaying) {
      lottieAnimation.play()
    }
  }, [isPlaying])

  return (
    <>
      {lottieImage && !animationDataMobile &&
        <div className="lottie-placeholder">
          <PuxMediaSelector
            mediaSelectorImage={lottieImage}
            width={500}
            height={200}
          />
        </div>
      }
      <div ref={ref} className={`lottie` + (size.width < 992 ? ` lottie-mobile` : ` lottie-desktop`) + (size.width < 992 && lottieImage?.resizePaths.length > 0 && !animationDataMobile ? ` hidden` : ``)}>
        {lottieAnimation.View}
      </div>
    </>
  )
}

export default EasySoftwareLottie
import '../../../Shared/templates/ErrorPage/ErrorPage.scss'

import { graphql } from 'gatsby'
import * as React from 'react'
import { FunctionComponent } from 'react'
import { PuxWysiwyg } from 'Shared/components'
import getBrowserLang from 'Shared/utils/getBrowserLang'
import PuxMetaTags from 'Shared/utils/PuxMetaTags'
import { useHeaderData } from '../queries/_use-header-data.js'
import { useFooterData } from '../queries/_use-footer-data'

import { puxErrorPageType } from '../../../Shared/templates/ErrorPage/ErrorPage'
import Layout from '../components/core/Layout'
//@ts-ignore
import { ReactComponent as NotFound } from '../svg/404.svg'
import { IHeaderData } from 'Root/Shared/queries/layout-queries'

export const query = graphql`
  query {
    orchard {
      puxErrorPage(where: { path_contains: "404" }) {
        localization {
          culture
        }
        htmlBody {
          html
        }
        puxMetaTags {
          metaTagsCanonicalUrl {
            html
          }
          metaTagsDescription {
            html
          }
          metaTagsImage {
            resizePaths(width: 1200, mode: "Min")
          }
          metaTagsKeywords {
            html
          }
          metaTagsOGDescription {
            html
          }
          metaTagsOGImage {
            resizePaths(width: 1200, mode: "Min")
          }
          metaTagsOGTitle {
            html
          }
          metaTagsRobots
          metaTagsTitle {
            html
          }
        }
      }
    }
  }
`

const page404: FunctionComponent<puxErrorPageType> = (props) => {
  const headerData: IHeaderData = {
    menu: [{
      menuItemsList: useHeaderData()
    }]
  }
  const footerData = {
    menu: [{
      menuItemsList: useFooterData()
    }]
  }

  const pageData = props.data.orchard.puxErrorPage.filter((page) =>
    page.localization.culture.startsWith(getBrowserLang())
  )

  if (!pageData.length) {
    const defaultData = props.data.orchard.puxErrorPage.filter((page) =>
      page.localization.culture.includes(`en-GB`)
    )

    pageData.push(defaultData[0])
  }

  const localizedData = pageData[0]

  return (
    <Layout footerData={footerData} headerData={headerData}>
      <PuxMetaTags {...localizedData.puxMetaTags} />
      <div className={`NotFound Container`}>
        <NotFound />
        <PuxWysiwyg content={localizedData.htmlBody} />
      </div>
    </Layout >
  )
}

export default page404

// import React, { FunctionComponent } from "react";

// const styleGuide: FunctionComponent<any> = (props) => {
//   return (
//     <div></div>
//   )
// }
// export default styleGuide;
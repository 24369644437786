import React, { FunctionComponent } from 'react'
import EasySoftwareEditorContainer from 'Root/Shared/components/EasySoftwareEditorContainer/EasySoftwareEditorContainer'
import {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'

import PuxButton, { PuxButtonType } from '../PuxButton/PuxButton'
import { CardHeadingColor } from './EasySoftwareCards'

export interface EasySoftwareCardItemType {
  contentItemId: string
  contentType: string
  displayText: string
  cardItemPerex: PuxWysiwygHtmlBodyType
  cardButtonPrimary: PuxButtonType
  cardButtonSecondary: PuxButtonType
}

export interface EasySoftwareCardItemProps {
  data: EasySoftwareCardItemType
  headingsColor: CardHeadingColor
  pageContentItemId: string
  editorPath: string
}

const EasySoftwareCardItem: FunctionComponent<EasySoftwareCardItemProps> = (
  props
) => {
  const primaryButton = props.data.cardButtonPrimary,
    secondaryButton = props.data.cardButtonSecondary

  return (
    <div className="CardItem">
      <h4
        className="CardItem-title h5"
      >
        {props.data.displayText}
      </h4>

      <div className="CardItem-perex">
        <EasySoftwareEditorContainer pageContentItemId={props.pageContentItemId} content={props.data.cardItemPerex} editorPath={props.editorPath} wysiwygId={props.data.contentItemId} />
      </div>

      <div className="CardItem-buttons">
        {primaryButton?.buttonLink.text && <PuxButton {...primaryButton} />}
        {secondaryButton?.buttonLink.text && <PuxButton {...secondaryButton} />}
      </div>
    </div>
  )
}

export default EasySoftwareCardItem

import React, { FunctionComponent } from 'react'

interface MediaVideoFile {
  paths: [string]
}

export interface PuxMediaVideoSelectorType extends MediaVideoFile {
  file: MediaVideoFile
  muted?: boolean
  autoplay?: boolean
  controls?: boolean
  loop?: boolean
  width?: string | number
  height?: string | number
}

const DEFAULT_VIDEO_WIDTH = 1200
const DEFAULT_VIDEO_HEIGHT = 1200

const PuxMediaVideoSelector: FunctionComponent<PuxMediaVideoSelectorType> = (
  props
) => {
  const isEmpty = props.file?.paths.length < 1

  if (!isEmpty) {
    return (
      <video
        autoPlay={props.autoplay ?? true}
        muted={props.muted ?? true}
        controls={props.controls ?? false}
        loop={props.loop ?? true}
        width={props.width ?? DEFAULT_VIDEO_WIDTH}
        height={props.height ?? DEFAULT_VIDEO_HEIGHT}
      >
        <source
          src={`${process.env.GATSBY_ORCHARD_API_URL}/media/${props.file?.paths}`}
          type='video/mp4'
        ></source>
      </video>
    )
  } else {
    return null
  }
}

export default PuxMediaVideoSelector

import './PuxRating.scss'

import React, { FunctionComponent } from 'react'
import PuxIconBuilder from 'Shared/components/PuxIconBuilder/PuxIconBuilder'

export interface PuxRatingType {
  contentItemId: string
  contentType: string // unused
  displayText: string
  ratingStars: number
  ratingMaxStars: number
}

const PuxRating: FunctionComponent<PuxRatingType> = (props) => {
  const content = []

  for (let i = 1; i <= props.ratingMaxStars; i++) {
    if (i <= props.ratingStars) {
      content.push(<span className='Star Star--full' key={i} />)
    } else {
      content.push(<span className='Star' key={i} />)
    }
  }

  return (
    <div className='Rating'>
      <h4 className={`Rating-title`}>{props.displayText}</h4>
      <div className={`Rating-stars`}>
        {` `}
        {content}
      </div>
    </div>
  )
}

export default PuxRating

import React, { FunctionComponent } from "react";
import PuxMediaSelector, {
  PuxMediaSelectorType,
} from "Root/Shared/components/content/mediaImage/PuxMediaSelector";
import { getLocalizedUrl } from "Shared/utils/localeURL";

export interface PuxLinkGalleryItemType {
  puxMediaSelector: PuxMediaSelectorType;
  linkGalleryItemUrl: {
    url: [string];
    internal: [boolean];
  };
}

const PuxLinkGalleryItem: FunctionComponent<PuxLinkGalleryItemType> = (props) =>
  props.linkGalleryItemUrl.url[0] != null ? (
    <a
      href={`${
        props.linkGalleryItemUrl.internal[0]
          ? getLocalizedUrl(props.linkGalleryItemUrl.url[0])
          : props.linkGalleryItemUrl.url[0]
      }`}
      target={props.linkGalleryItemUrl.internal[0] ? `_self` : `_blank`}
      className="Gallery-item"
    >
      <PuxMediaSelector {...props.puxMediaSelector} width={150} height={50} />
    </a>
  ) : (
    <div className="Gallery-item">
      <PuxMediaSelector {...props.puxMediaSelector} width={150} height={50} />
    </div>
  );
  
export default PuxLinkGalleryItem;

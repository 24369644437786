import './EasySoftwareTimeline.scss'

import React, { FunctionComponent } from 'react'

export interface TimelineItemType {
  displayText: string
  timelineItemContent: string
  timelineItemURL: string
  timelineItemRichContent: {
    html: string
  }
}

export interface EasySoftwareTimelineType {
  displayText: string
  timelineItems: {
    contentItems: [TimelineItemType]
  }
}

const EasySoftwareTimeline: FunctionComponent<EasySoftwareTimelineType> = (
  props
) => (
  <div className='Timeline'>
    <div className='Timeline-items'>
      {props.timelineItems &&
        props.timelineItems.contentItems &&
        props.timelineItems.contentItems.map((item, idx) => (
          <div className='TimelineItem' key={idx}>
            {item.timelineItemURL != null ? (
              <a href={item.timelineItemURL} className='TimelineItem-year'>
                {item.displayText}
              </a>
            ) : (
              <h3 className='TimelineItem-year'>{item.displayText}</h3>
            )}
            <span className='TimelineItem-marker'>
              <span className='TimelineItem-marker-inner'>
                <span className='TimelineItem-marker-center'></span>
              </span>
            </span>
            {item.timelineItemRichContent?.html ? (
              <p
                className='TimelineItem-content'
                dangerouslySetInnerHTML={{
                  __html: item.timelineItemRichContent?.html,
                }}
              />
            ) : (
              <p className='TimelineItem-content'>{item.timelineItemContent}</p>
            )}
          </div>
        ))}
    </div>
  </div>
)

export default EasySoftwareTimeline

import './VideoTutorialPart.scss'

import React, { FunctionComponent } from 'react'
import { dt } from 'Shared/utils/dynamicRS'

import { PuxMediaSelectorType } from '../../content/mediaImage/PuxMediaSelector'
import PuxVideo, { PuxVideoType } from '../../PuxVideo/PuxVideo'
import { PuxWysiwygHtmlBodyType } from '../../PuxWysiwyg/PuxWysiwyg'

export interface VideoTutorialPartType {
  contentType: 'EasySoftwareVideoTutorial'
  contentItemId: string
  displayText: string
  createdUtc: string
  videoTutorialSolution?: string
  videoTutorialLevel?: string
  videoTutorialRole?: string
  puxVideo: PuxVideoType
  videoTutorialImage: PuxMediaSelectorType
  videoTutorialPerex?: PuxWysiwygHtmlBodyType
}

const rootClass = `video-tutorial-part`

const VideoTutorialPart: FunctionComponent<VideoTutorialPartType> = (props) => {
  const project: string = process.env.PROJECT
    ? process.env.PROJECT
    : `EasyRedmineGatsby`

  const localizedLabel = dt(props.videoTutorialSolution ?? ``)

  return (
    <div
      className={`pux-repeater-item ${rootClass}-wrapper ${project ? `wrapper-` + project.toLocaleLowerCase() + `-helper` : ``
        }`}
    >
      <div className='VideoTutorialPart-item'>
        <PuxVideo
          videoStructuredDataContentUrl={
            props.puxVideo.videoStructuredDataContentUrl
          }
          videoStructuredDataDescription={
            props.puxVideo.videoStructuredDataDescription
          }
          videoStructuredDataName={props.puxVideo.videoStructuredDataName}
          videoStructuredDataUploadDate={props.puxVideo.videoStructuredDataName}
          videoThumbnail={props.videoTutorialImage.mediaSelectorImage}
          videoYTID={props.puxVideo.videoYTID}
          videoLabel={localizedLabel}
          videoTitle={props.displayText}
          videoPerex={props.videoTutorialPerex?.html}
        />
      </div>
    </div>
  )
}

export default VideoTutorialPart

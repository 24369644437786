import './FunctionDetail.scss'
import '../../components/PuxRepeater/PuxRepeaterParts/FunctionPart.scss'
//@ts-ignore
import Layout from '@componentsfld/core/Layout'
import { HeadProps } from 'gatsby'
import React, { FunctionComponent, lazy, Suspense } from 'react'
import WidgetBuilder from 'Shared/components/builders/WidgetBuilder'
import PuxMediaSelector from 'Shared/components/content/mediaImage/PuxMediaSelector'
import EasySoftwareBreadcrumbs from 'Shared/components/EasySoftwareBreadcrumbs/EasySoftwareBreadcrumbs'
import PuxMetaTagsGatsby from 'Shared/utils/PuxMetaTagsGatsby'
import { GenericPageProps, IEasySoftwareFunction } from 'Root/Shared/queries/page-queries'
import { IPageContext } from 'Root/Shared/utils/data/buildTypedPage'
import EasySoftwareEditorContainer from 'Shared/components/EasySoftwareEditorContainer/EasySoftwareEditorContainer'

const PuxLightboxGallery = lazy(
  () => import(`Shared/components/PuxLightboxGallery/PuxLightboxGallery`)
)
const PuxVideo = lazy(() => import(`Shared/components/PuxVideo/PuxVideo`))

const FunctionDetail: FunctionComponent<GenericPageProps<IEasySoftwareFunction>> = (props) => {
  const pageData = props.pageContext.pageData
  const breadcrumbsData = props.pageContext.breadcrumbs
  const headerData = props.pageContext.headerData
  const footerData = props.pageContext.footerData
  const editorPath = `widgetZone,widgets`

  function renderMediaComponent() {
    if (
      typeof pageData.puxVideo.videoYTID?.html !== `string` ||
      pageData.puxVideo.videoYTID?.html === ``
    ) {
      return <PuxMediaSelector {...pageData.functionImage} />
    }
    if (
      typeof pageData.puxVideo.videoYTID?.html === `string` &&
      pageData.puxVideo.videoYTID?.html !== ``
    ) {
      return (
        <Suspense fallback={<></>}>
          <PuxVideo
            videoStructuredDataDescription={
              pageData.puxVideo.videoStructuredDataDescription
            }
            videoStructuredDataContentUrl={
              pageData.puxVideo.videoStructuredDataContentUrl
            }
            videoStructuredDataName={pageData.puxVideo.videoStructuredDataName}
            videoStructuredDataUploadDate={
              pageData.puxVideo.videoStructuredDataUploadDate
            }
            videoThumbnail={pageData.functionImage.mediaSelectorImage}
            videoYTID={pageData.puxVideo.videoYTID}
          />
        </Suspense>
      )
    }

    return null
  }

  return (
    <Layout localizedPath={pageData.localization?.localizations}
      footerData={footerData}
      headerData={headerData}
      pageContentItemId={pageData.contentItemId}
    >
      <div className='Container'>
        <div className='FunctionPage'>
          <EasySoftwareBreadcrumbs items={breadcrumbsData} />
          <h1 className={`FunctionPage-title`}>{pageData.displayText}</h1>
          <div className={`FunctionPage-perex perex`}>
            <EasySoftwareEditorContainer pageContentItemId={pageData.contentItemId} wysiwygId={pageData.contentItemId + `-perex`} content={pageData.functionPerex} editorPath={`functionPerex,html`} />
          </div>
          <div className='Function'>
            <div className='Function-image'>
              <div className='Function-mainImage'>
                {renderMediaComponent()}
                <Suspense fallback={<></>}>
                  <PuxLightboxGallery {...pageData.functionGallery} />
                </Suspense>
              </div>
            </div>

            <div className='Function-content'>
              <div className={`FunctionPage-content`}>
                <EasySoftwareEditorContainer pageContentItemId={pageData.contentItemId} wysiwygId={pageData.contentItemId + `-content`} content={pageData.functionContent} editorPath={`functionContent,html`} />
              </div>
            </div>
          </div>
          <WidgetBuilder
            widgetBuilderData={props.pageContext?.widgets?.widgetZone}
            pageContentItemId={pageData.contentItemId}
            editorPath={editorPath}
          />
        </div>
      </div>
    </Layout>
  )
}

export default FunctionDetail

export function Head(props: HeadProps<never, IPageContext<IEasySoftwareFunction>>) {
  const pageData = props.pageContext.pageData
  const metaTags = props.pageContext.metaTags

  return <PuxMetaTagsGatsby {...metaTags} localizedPaths={pageData.localization?.localizations} />
}

import './EasySoftwareFeatures.scss'

import React, { FunctionComponent } from 'react'
import EasySoftwareEditorContainer from 'Root/Shared/components/EasySoftwareEditorContainer/EasySoftwareEditorContainer'
import {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'

import PuxButton, { PuxButtonType } from '../PuxButton/PuxButton'
import PuxImageBox, {
  PuxImageBoxLayout,
  PuxImageBoxType,
} from '../PuxImageBox/PuxImageBox'

export interface EasySoftwareFeaturesType {
  displayText: string
  featuresPerex: PuxWysiwygHtmlBodyType
  featuresMainButton: PuxButtonType
  featuresSecondaryButton: PuxButtonType
  featuresList: {
    contentItems: [PuxImageBoxType]
  }
  pageContentItemId: string
  editorPath: string
  contentItemId: string
}

// const rootClass = `features`

const EasySoftwareFeatures: FunctionComponent<EasySoftwareFeaturesType> = (
  props
) => {
  const classesItems: string[] = [`Features-items`]

  return (
    <div className='Features'>
      <h2 className='Features-title'>{props.displayText}</h2>

      <div className='Features-perex perex'>
        <EasySoftwareEditorContainer editorPath={props.editorPath + `,puxSection,sectionPerex,html`} pageContentItemId={props.pageContentItemId} content={props.featuresPerex} wysiwygId={props.contentItemId} />
      </div>

      <div className={classesItems.join(` `)}>
        {props.featuresList &&
          props.featuresList.contentItems &&
          props.featuresList.contentItems.map((item) => (
            <PuxImageBox
              key={item.contentItemId}
              layout={PuxImageBoxLayout.HORIZONTAL}
              columns={2}
              editorPath={props.editorPath + `,flowPart,widgets,cid-${props.contentItemId},featuresList,contentItems`}
              pageContentItemId={props.pageContentItemId} 
              wysiwygId={props.contentItemId}
              {...item}
            />
          ))}
      </div>

      <div className='Features-buttons'>
        <PuxButton {...props.featuresMainButton} />
        <PuxButton {...props.featuresSecondaryButton} />
      </div>
    </div>
  )
}

export default EasySoftwareFeatures

import React, { FunctionComponent } from 'react'
import { WidgetBase } from 'Shared/components/builders/WidgetBuilder'
import WidgetContainer from 'Shared/components/builders/WidgetContainer'

import EasySoftwareTimeline, {
  EasySoftwareTimelineType,
} from '../EasySoftwareTimeline/EasySoftwareTimeline'

export interface WidgetEasySoftwareTimelineType
  extends WidgetBase,
  EasySoftwareTimelineType {
  contentType: 'EasySoftwareTimeline'
}

const WidgetEasySoftwareTimeline: FunctionComponent<WidgetEasySoftwareTimelineType> = (
  props
) => (
  <WidgetContainer
    {...props.puxWidgetProperties}
    {...props.puxWidgetAnimation}
    {...props.metadata}
    cropped
  >
    <EasySoftwareTimeline
      displayText={props.displayText}
      timelineItems={props.timelineItems}
    />
  </WidgetContainer>
)
export default WidgetEasySoftwareTimeline

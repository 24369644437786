import './PuxAnchor.scss'

import React, { FunctionComponent } from 'react'

interface PuxAnchorType {
  id: string
  children?: React.ReactNode
}

const rootClass = `pux-anchor`

const PuxAnchor: FunctionComponent<PuxAnchorType> = (props) => (
  <div className={rootClass}>
    <span className={`${rootClass}-shift`} id={props.id}></span>
    {props.children ?? null}
  </div>
)

export default PuxAnchor

import EasySoftwareBenefits from './EasySoftwareBenefits/EasySoftwareBenefits'
import EasySoftwareCards from './EasySoftwareCards/EasySoftwareCards'
import EasySoftwareCaseStudies from './EasySoftwareCaseStudies/EasySoftwareCaseStudies'
import EasySoftwareFeatures from './EasySoftwareFeatures/EasySoftwareFeatures'
import EasySoftwareReviews from './EasySoftwareReviews/EasySoftwareReviews'
import EasySoftwareSolutions from './EasySoftwareSolutions/EasySoftwareSolutions'
import EasySoftwareVideoBanner from './EasySoftwareVideoBanner/EasySoftwareVideoBanner'
import PuxButton from './PuxButton/PuxButton'
import PuxCarousel from './PuxCarousel/PuxCarousel'
import PuxGallery from './PuxGallery/PuxGallery'
import PuxImageBox from './PuxImageBox/PuxImageBox'
import PuxImageContent from './PuxImageContent/PuxImageContent'
import PuxNumber from './PuxNumber/PuxNumber'
import PuxRating from './PuxRating/PuxRating'
import PuxTopImage from './PuxTopImage/PuxTopImage'
import PuxVideo from './PuxVideo/PuxVideo'
import PuxWysiwyg from './PuxWysiwyg/PuxWysiwyg'

export {
  EasySoftwareBenefits,
  EasySoftwareCards,
  EasySoftwareCaseStudies,
  EasySoftwareFeatures,
  EasySoftwareReviews,
  EasySoftwareSolutions,
  EasySoftwareVideoBanner,
  PuxButton,
  PuxCarousel,
  PuxGallery,
  PuxImageBox,
  PuxImageContent,
  PuxNumber,
  PuxRating,
  PuxTopImage,
  PuxVideo,
  PuxWysiwyg,
}

/* eslint-disable indent */

/* eslint-disable prettier/prettier */
import './PuxImageContent.scss'



import React, { FunctionComponent, useState } from 'react'

import { Collapse } from 'react-collapse'

import useWindowDimensions from 'Shared/utils/useWindowDimensions'

import { t } from 'ttag'


import EasySoftwareLottie from '../content/mediaAnimation/EasySoftwareLottie'

import EasySoftwareBackgroundVideo from '../content/mediaBackgroundVideo/EasySoftwareBackgroundVideo'
import PuxMediaSelector, {
  PuxMediaSelectorType,
} from '../content/mediaImage/PuxMediaSelector'
import { MediaSelectorImageType } from '../content/mediaImage/PuxPicture'
import EasySoftwareEditorContainer from '../EasySoftwareEditorContainer/EasySoftwareEditorContainer'

import PuxButton, { PuxButtonType } from '../PuxButton/PuxButton'

import PuxLightboxGallery from '../PuxLightboxGallery/PuxLightboxGallery'

import PuxVideo, { PuxVideoType } from '../PuxVideo/PuxVideo'

import { PuxWysiwygHtmlBodyType } from '../PuxWysiwyg/PuxWysiwyg'

export enum ImageContentButtonAlign {
  LEFT = `left`,
  RIGHT = `right`,
  CENTER = `center`,
}

export interface PuxImageContentType {
  pageContentItemId: string,
  editorPath: string,
  contentItemId: string
  imageContentText: PuxWysiwygHtmlBodyType
  imageContentCollapseContent: PuxWysiwygHtmlBodyType
  imageContentImagePosition: string
  imageContentTextAlign: string
  imageContentButtonAlign: ImageContentButtonAlign
  imageContentGallery: PuxMediaSelectorType
  imageContentImageMobile: PuxMediaSelectorType
  puxMediaSelector: PuxMediaSelectorType
  puxButton: PuxButtonType
  puxVideo: PuxVideoType
  puxImageContentMedia: {
    cultureContentItems: imageContentMediaItem[]
  }
}

interface imageContentMediaItem {
  displayText
  contentType
  heroBannerBackgroundImage: MediaSelectorImageType
  backgroundVideoFileWEBM: {
    paths: [string],
    type: [string]
  },
  backgroundVideoFileMP4: {
    paths: [string],
    type: [string]
  },
  backgroundVideoPlaceholder: {
    paths: [string]
  },
  lottieJSON: string,
  backgroundVideoLoop: boolean,
  backgroundVideoAutoplay: boolean,
  backgroundVideoControls: boolean,
  backgroundVideoShowOnMobile: boolean,
  puxVideo: PuxVideoType
}

const PuxImageContent: FunctionComponent<PuxImageContentType> = (props) => {
  const [isOpened, setIsOpened] = useState(false)
  const { width } = useWindowDimensions()

  const hasMobileImage = props?.imageContentImageMobile?.mediaSelectorImage?.resizePaths.length > 0;

  const ImageContentImage = (hasMobileImage ?
    (width < 768 ?
      <PuxMediaSelector
        {...props.imageContentImageMobile}
        width={720}
        height={400}
      /> :
      <PuxMediaSelector
        {...props.puxMediaSelector}
        width={720}
        height={400}
      />)
    :
    <PuxMediaSelector
      {...props.puxMediaSelector}
      width={720}
      height={400}
    />
  )

  const ImageContentGallery = (
    <PuxLightboxGallery
      {...props.imageContentGallery}
      disableLazyload
      width={160}
      height={80}
    />
  )

  const className = [`ImageContent`]

  if (props.imageContentImagePosition) {
    className.push(props.imageContentImagePosition)
  }

  if (props.imageContentTextAlign) {
    className.push(props.imageContentTextAlign)
  }


  function returnMedia(mediaArray: imageContentMediaItem[]) {
    if (mediaArray && mediaArray.length) {
      const media = mediaArray[0]
      switch (media.contentType) {
        case `EasySoftwareLottie`:
          return (
            <EasySoftwareLottie
              animationData={JSON.parse(media.lottieJSON)}
            />
          );
        case `EasySoftwareHeroBannerBackground`:
          return (
            <PuxMediaSelector
              mediaSelectorImage={media.heroBannerBackgroundImage}
              width={800}
              height={470}
              disableLazyload={true}
            />
          );
        case `EasySoftwareBackgroundVideo`:
          return (
            <EasySoftwareBackgroundVideo
              {...media}
            />
          );
      }
    }

    return null
  }

  return (
    <div className={className.join(` `)}>
      <div className='ImageContent-image'>
        <div className='ImageContent-mainImage'>
          {returnMedia(props.puxImageContentMedia?.cultureContentItems)}
          {
            (!(props.puxImageContentMedia?.cultureContentItems?.length > 0) && props.puxMediaSelector) ?
              ImageContentImage
              : null
          }
          {
            ((!props.puxImageContentMedia?.cultureContentItems?.length || props.puxImageContentMedia?.cultureContentItems[0]?.contentType !== `PuxVideo`) && props.puxVideo?.videoYTID?.html) ? (
              <PuxVideo
                videoStructuredDataContentUrl={
                  props.puxVideo?.videoStructuredDataContentUrl
                }
                videoStructuredDataDescription={
                  props.puxVideo?.videoStructuredDataDescription
                }
                videoStructuredDataName={props.puxVideo?.videoStructuredDataName}
                videoStructuredDataUploadDate={
                  props.puxVideo?.videoStructuredDataUploadDate
                }
                videoThumbnail={props.puxVideo?.videoThumbnail}
                videoYTID={props.puxVideo?.videoYTID}
              />
            ) : null
          }
          {
            (props.puxImageContentMedia?.cultureContentItems?.length > 0 && props.puxImageContentMedia?.cultureContentItems[0]?.contentType === `PuxVideo`) ? (
              <PuxVideo
                videoStructuredDataContentUrl={
                  props.puxImageContentMedia?.cultureContentItems[0]?.puxVideo?.videoStructuredDataContentUrl
                }
                videoStructuredDataDescription={
                  props.puxImageContentMedia?.cultureContentItems[0]?.puxVideo?.videoStructuredDataDescription
                }
                videoStructuredDataName={props.puxImageContentMedia?.cultureContentItems[0]?.puxVideo?.videoStructuredDataName}
                videoStructuredDataUploadDate={
                  props.puxImageContentMedia?.cultureContentItems[0]?.puxVideo?.videoStructuredDataUploadDate
                }
                videoThumbnail={props.puxImageContentMedia?.cultureContentItems[0]?.puxVideo?.videoThumbnail}
                videoYTID={props.puxImageContentMedia?.cultureContentItems[0]?.puxVideo?.videoYTID}
              />
            ) : null
          }
          {
            width > 767 && (
              <Collapse isOpened={isOpened}>{ImageContentGallery}</Collapse>
            )
          }
        </div >
      </div >
      <div className='ImageContent-content'>
        <EasySoftwareEditorContainer pageContentItemId={props.pageContentItemId} content={props.imageContentText} editorPath={props.editorPath + `,cid-${props.contentItemId},puxImageContent,imageContentText,html`} wysiwygId={props.contentItemId + `-text`} />
        {props.puxButton.buttonLink.text[0] && (
          <div
            className={`ImageContent-button ${props.imageContentButtonAlign
              ? `button-` + props.imageContentButtonAlign
              : ``
              }`}
          >
            <PuxButton {...props.puxButton} />
          </div>
        )}
        {(props.imageContentCollapseContent?.html ||
          props.imageContentGallery?.mediaSelectorImage?.resizePaths[0]) && (
            <>
              <Collapse
                initialStyle={{ height: `0px`, overflow: `hidden` }}
                isOpened={isOpened}
              >
                {props.imageContentCollapseContent?.html && (
                  <EasySoftwareEditorContainer pageContentItemId={props.pageContentItemId} content={props.imageContentCollapseContent} editorPath={props.editorPath + `,cid-${props.contentItemId},puxImageContent,imageContentCollapseContent,html`} wysiwygId={props.contentItemId + `-collapse`} />
                )}
                {width < 768 && ImageContentGallery}
              </Collapse>
              <div className={`ImageContent-moreLess`}>
                <button
                  className={`Button Button--link Button--medium${isOpened ? ` is-active` : ``
                    }`}
                  onClick={() => setIsOpened(!isOpened)}
                >
                  {isOpened
                    ? t`Redmine.Function.LessInfo`
                    : t`Redmine.Function.MoreInfo`}
                </button>
              </div>
            </>
          )}
      </div>
    </div >
  )
}

export default PuxImageContent

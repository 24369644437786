import './EasySoftwareTestimonials.scss'

import React, { FunctionComponent } from 'react'

import EasySoftwareTestimonialItem, {
  EasySoftwareTestimonialType,
} from './EasySoftwareTestimonialItem'

export interface EasySoftwareTestimonialsType {
  contentItemId?: string
  displayText: string
  testimonialsList: {
    cultureContentItems: [EasySoftwareTestimonialType]
  }
}

// const rootClass = `pux-testimonials`

const EasySoftwareTestimonials: FunctionComponent<EasySoftwareTestimonialsType> = (
  props
) => {
  const items = props.testimonialsList?.cultureContentItems
  return (
    <div className='Testimonials'>
      <div className='Testimonials-items'>
        {items &&
          items.map((item, idx) => {
            if (item) {
              return <EasySoftwareTestimonialItem key={idx} {...item} />
            }
          })}
      </div>
    </div>
  )
}

export default EasySoftwareTestimonials

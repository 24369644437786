import React, { FunctionComponent, useState } from 'react'

export interface YoutubeIconType {
  icon: 'YoutubeIcon'
  options?: YoutubeIconPropsType
}

interface YoutubeIconPropsType {
  className?: string
  color: YoutubeIconStateType
  hoverColor: YoutubeIconStateType
}

interface YoutubeIconStateType {
  colorPrimary: string
  colorSecondary: string
}

const YoutubeIcon: FunctionComponent<YoutubeIconPropsType> = (props) => {
  const defaultTheme = props.color
  const hoverTheme = props.hoverColor
  const [colorTheme, setColorTheme] = useState<YoutubeIconStateType>(
    defaultTheme
  )

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 30 30'
      width='100%'
      className={props.className?.toLowerCase()}
      onMouseEnter={() => setColorTheme(hoverTheme)}
      onMouseLeave={() => setColorTheme(defaultTheme)}
    >
      <circle fill={colorTheme.colorPrimary} cx='15' cy='15' r='15' />
      <path
        d='M23.15,10.88a2.12,2.12,0,0,0-1.5-1.51A48.94,48.94,0,0,0,15,9a51.7,51.7,0,0,0-6.65.35,2.18,2.18,0,0,0-1.5,1.53A22.78,22.78,0,0,0,6.5,15a22.66,22.66,0,0,0,.35,4.12,2.12,2.12,0,0,0,1.5,1.51A49,49,0,0,0,15,21a51.7,51.7,0,0,0,6.65-.35,2.16,2.16,0,0,0,1.5-1.51A22.87,22.87,0,0,0,23.5,15,22,22,0,0,0,23.15,10.88Zm-9.84,6.69V12.43L17.73,15Z'
        fill={colorTheme.colorSecondary}
      />
    </svg>
  )
}

export default YoutubeIcon

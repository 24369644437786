/* eslint-disable prettier/prettier */
import './PuxImageBox.scss'

import React, { FunctionComponent } from 'react'
import PuxMediaSelector, {
  PuxMediaSelectorType,
} from 'Root/Shared/components/content/mediaImage/PuxMediaSelector'
import EasySoftwareEditorContainer from 'Root/Shared/components/EasySoftwareEditorContainer/EasySoftwareEditorContainer'
import PuxButton, { PuxButtonType } from 'Shared/components/PuxButton/PuxButton'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'

export enum PuxImageBoxLayout {
  VERTICAL = `vertical`,
  HORIZONTAL = `horizontal`,
}

export interface PuxImageBoxType {
  columns?: number
  layout?: PuxImageBoxLayout
  contentItemId: string
  displayText: string
  imageBoxImage: PuxMediaSelectorType
  imageBoxContent: PuxWysiwygHtmlBodyType
  imageBoxButton: PuxButtonType
  imageBoxLightbox?: boolean
  editorPath?: string,
  pageContentItemId?: string
  wysiwygId?: string
}

const DEFAULT_LAYOUT: PuxImageBoxLayout = PuxImageBoxLayout.VERTICAL

const PuxImageBox: FunctionComponent<PuxImageBoxType> = (props) => {
  const layout: PuxImageBoxLayout = props.layout ? props.layout : DEFAULT_LAYOUT

  return (
    <div className={`ImageBox ImageBox--${layout}`}>
      {props.imageBoxImage?.mediaSelectorImage?.resizePaths?.length > 0 && (
        <div className='ImageBox-image'>
          <PuxMediaSelector {...props.imageBoxImage} height={150} width={150} />
        </div>
      )}

      <div className='ImageBox-text'>
        {(() => {
          if (props.displayText && props.displayText.length) {
            return props.pageContentItemId && props.editorPath ?
            <h5 className='ImageBox-title'>
              <EasySoftwareEditorContainer
                type="input"
                content={props.displayText}
                wysiwygId={props.contentItemId+"-title"}
                editorPath={props.editorPath + `,cid-${props.contentItemId},displayText`}
                pageContentItemId={props.pageContentItemId}
              />
            </h5>
            :
            <h5 className='ImageBox-title'>{props.displayText}</h5>
          }
        })()}

        <div className='ImageBox-content'>
          {props.pageContentItemId && props.editorPath ?
            <EasySoftwareEditorContainer
              content={props.imageBoxContent}
              wysiwygId={props.contentItemId}
              editorPath={props.editorPath + `,cid-${props.contentItemId},puxImageBox,imageBoxContent,html`}
              pageContentItemId={props.pageContentItemId}
            />
            :
            <PuxWysiwyg
              content={props.imageBoxContent}
              wysiwygId={props.contentItemId}
            />
          }

        </div>
        <PuxButton {...props.imageBoxButton} />
      </div>
    </div>
  )
}

export default PuxImageBox

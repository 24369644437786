/* eslint-disable prettier/prettier */

import React, { FunctionComponent, useEffect, useRef } from 'react'

import { MauticOnModalInit as EPMauticOnModalInit } from '../../mautic/EasyProject/EPMauticScripts'
import { MauticOnModalInit as ERMauticOnModalInit } from '../../mautic/EasyRedmine/ERMauticScripts'
export interface EasySoftwareEmbeddedCodeType {
  contentType: 'EasySoftwareEmbeddedCode'
  contentItemId: string
  embeddedCodeShortCode: {
    html: string
  }
  embeddedCodeType: 'head' | 'body' | 'html'
}

export interface EasySoftwareDangerousEmbeddedCodeType {
  contentType: 'EasySoftwareDangerousEmbeddedCode'
  contentItemId: string
  dangerousEmbeddedCodeSourceCode: {
    html: string
  }
  dangerousEmbeddedCodeType: EmbeddedCodeType
}

type EasySoftwareEmbeddedCode =
  | EasySoftwareEmbeddedCodeType
  | EasySoftwareDangerousEmbeddedCodeType

type EmbeddedCodeType = 'head' | 'body' | 'html'

const rootClass = `embeddedcode`

const EasySoftwareEmbeddedCode: FunctionComponent<EasySoftwareEmbeddedCode> = (
  props
) => {
  const activeEmbeddedCodeRef = useRef<HTMLElement>(null)
  let embeddedCode: string
  let embeddedCodeType: EmbeddedCodeType

  switch (props.contentType) {
    case `EasySoftwareEmbeddedCode`:
      embeddedCodeType = props.embeddedCodeType
      embeddedCode = props.embeddedCodeShortCode?.html
      break
    case `EasySoftwareDangerousEmbeddedCode`:
      embeddedCodeType = props.dangerousEmbeddedCodeType
      embeddedCode = props.dangerousEmbeddedCodeSourceCode?.html
      break
  }

  if (typeof embeddedCode === `string`) {
    switch (embeddedCodeType) {
      case `head`:
        if (typeof document === `undefined`) return null
        const frag = document
          .createRange()
          .createContextualFragment(embeddedCode.trim())
        if (frag === null) {
          console.warn(
            `EmbeddedCode id=${props.contentItemId} has null Fragment`
          )
        } else {
          document.head.appendChild(frag)
        }
        return null
      case `body`:
        useEffect(() => {
          const frag = document
            .createRange()
            .createContextualFragment(embeddedCode.trim())
          if (frag === null) {
            console.warn(
              `EmbeddedCode id=${props.contentItemId} has null Fragment`
            )
          } else {
            if (embeddedCode.includes(`data-shortcode="mauticform"`)) {
              const codeWrapper = document.querySelector(
                `#embedded-code-` + props.contentItemId
              )
              if (codeWrapper) {
                codeWrapper.appendChild(frag)
              }
            } else {
              document.body.appendChild(frag)
            }

            const pricingContainer = document.querySelector(`#easy_pricing_container`) as HTMLElement
            if (pricingContainer) {
              if (process.env.PROJECT === `EasyRedmineGatsby`) {
                window.addEventListener(`contact-modal-opened`, () =>
                  ERMauticOnModalInit(pricingContainer)
                );
              }

              if (process.env.PROJECT === `EasyProjectGatsby`) {
                window.addEventListener(`contact-modal-opened`, () =>
                  EPMauticOnModalInit(pricingContainer)
                );
              }
            }
          }
        }, [])
      case `html`:
        useEffect(() => {
          if (activeEmbeddedCodeRef.current) {
            const context = activeEmbeddedCodeRef.current

            if (process.env.PROJECT === `EasyRedmineGatsby`) {
              ERMauticOnModalInit(context)
            }

            if (process.env.PROJECT === `EasyProjectGatsby`) {
              EPMauticOnModalInit(context)
            }
          }
        }, [])
        return (
          <div
            ref={activeEmbeddedCodeRef}
            className={rootClass}
            id={props.contentItemId}
            dangerouslySetInnerHTML={{ __html: embeddedCode }}
          ></div>
        )
      default:
        console.warn(
          `EmbeddedCode id=${props.contentItemId} has empty field "embeddedCodeType"!`
        )
        return null
    }
  }
  console.warn(`EmbeddedCode id=${props.contentItemId} is empty!`)
  return null
}

export default EasySoftwareEmbeddedCode

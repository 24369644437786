import React, { FunctionComponent, useState } from 'react'

export interface PlusIconType {
  icon: 'PlusIcon'
  options?: PlusIconPropsType
}

interface PlusIconPropsType {
  className?: string
  color: PlusIconStateType
  hoverColor: PlusIconStateType
}

interface PlusIconStateType {
  colorPrimary: string
}

const PlusIcon: FunctionComponent<PlusIconPropsType> = (props) => {
  const defaultTheme = props.color
  const hoverTheme = props.hoverColor
  const [colorTheme, setColorTheme] = useState<PlusIconStateType>(defaultTheme)

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 12 12'
      width='100%'
      className={props.className?.toLowerCase()}
      onMouseEnter={() => setColorTheme(hoverTheme)}
      onMouseLeave={() => setColorTheme(defaultTheme)}
    >
      <g>
        <path
          fill={colorTheme.colorPrimary}
          d='M12,6a1,1,0,0,1-1,1H7v4a1,1,0,0,1-2,0V7H1A1,1,0,0,1,1,5H5V1A1,1,0,0,1,7,1V5h4A1,1,0,0,1,12,6Z'
        />
      </g>
    </svg>
  )
}

export default PlusIcon

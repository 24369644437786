import { ErrorMessage } from '@hookform/error-message'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { dt } from 'Root/Shared/utils/dynamicRS'

import { FormFieldMultipleType } from '../builders/FormBuilder'
import { WidgetProperitesPart } from '../builders/WidgetBuilder'
import FormErrorHelper from './FormErrorHelper'
import { getClass, getRequiredField } from './formFieldHelper'

const FormFieldMultipleWidget: React.FC<{
  props: FormFieldMultipleType
  widgetProperties?: WidgetProperitesPart
}> = ({ props, widgetProperties }) => {
  const formElement = props?.puxFormElement
  const formValidation = props?.puxFormElementValidation
  const formOptions = props?.puxFormElementOptions

  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <div
      className={`FormGroup ${getClass(
        props?.metadata?.size,
        widgetProperties
      )} FormGroup--select`}
    >
      <label htmlFor={formElement?.formElementID}>
        {formElement?.formElementLabel}
        {` `}
        {getRequiredField(
          props?.puxFormElementValidation?.formElementValidationRequired
        )}
      </label>
      <Controller
        name={formElement?.formElementName}
        control={control}
        rules={{
          required: formValidation.formElementValidationRequired
            ? dt(`PuxForm.Error.Required`)
            : false,
        }}
        render={({ field }) => (
          <select
            {...field}
            defaultValue={formElement?.formElementDefaultValue}
            placeholder={formElement?.formElementPlaceholder}
            className={formOptions?.formElementOptionsCssClass ?? ``}
            disabled={formOptions?.formElementOptionsDisabled}
          >
            {formElement?.formElementPlaceholder && (
              <option value=''>{`- ${formElement?.formElementPlaceholder} -`}</option>
            )}
            {props?.multipleOptions?.split(`\r\n`)?.map((option, index) => {
              const value = option.substring(0, option.indexOf(`;`))
              const displayText = option.substring(option.lastIndexOf(`;`) + 1)
              return (
                <option key={index} value={value}>
                  {displayText}
                </option>
              )
            })}
          </select>
        )}
      />
      <ErrorMessage
        render={(data) => <FormErrorHelper {...data} />}
        errors={errors}
        name={formElement?.formElementID ?? ``}
      />
    </div>
  )
}

export default FormFieldMultipleWidget

import React, { FunctionComponent } from 'react'
import { WidgetBase } from 'Shared/components/builders/WidgetBuilder'
import WidgetContainer from 'Shared/components/builders/WidgetContainer'

import EasySoftwareAccordion, {
  EasySoftwareAccordionType,
} from '../EasySoftwareAccordion/EasySoftwareAccordion'

export interface WidgetEasySoftwareAccordionType extends WidgetBase, EasySoftwareAccordionType {
  contentType: 'EasySoftwareAccordion'
}

const WidgetEasySoftwareAccordion: FunctionComponent<WidgetEasySoftwareAccordionType> = (
  props
) => (
  <WidgetContainer
    {...props.puxWidgetProperties}
    {...props.puxWidgetAnimation}
    {...props.metadata}
    fullWidth
  >
    <EasySoftwareAccordion
      bag={props.bag}
      accordionContentPosition={props.accordionContentPosition}
      accordionContentPositionDesktop={props.accordionContentPositionDesktop}
      accordionBackground={props.accordionBackground}
      accordionContentLocation={props.accordionContentLocation}
      pageContentItemId={props.pageContentItemId}
      accordionRatio={props.accordionRatio}
      editorPath={props.editorPath}
    />
  </WidgetContainer>
)

export default WidgetEasySoftwareAccordion

import { useState, useEffect } from 'react'

export function usePreviewStatus() {
    const [isPreview, setIsPreview] = useState(false);
    useEffect(() => {
        if (typeof window !== "undefined") {
            if (readPreviewCookie("EasySoftwarePreviewMode") === "true") {
                setIsPreview(true);
                return;
            } else {
                setIsPreview(false);
                return;
            }
        }
    }, []);
    return isPreview;
}

function readPreviewCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }

    return null;
}

export function resetPreviewCookie() {
    document.cookie = `EasySoftwarePreviewMode=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
}

export function addPreviewCookie() {
    var date = new Date();
    date.setTime(date.getTime() + 31 * 24 * 60 * 60 * 1000)
    var dateString = date.toLocaleDateString();
    document.cookie = `EasySoftwarePreviewMode=true` + `; expires=` + dateString + `;SameSite=Lax;`;
}
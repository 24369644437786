import './PuxContent.scss'

import React, { FunctionComponent, ReactElement } from 'react'

import WidgetBuilder from '../builders/WidgetBuilder'

interface ContentType {
  contentModifier?: string
  sideCards?: any
  sideContent?: ReactElement
}

const PuxContent: FunctionComponent<ContentType> = (props) => {
  const sideCardsData = props.sideCards?.length > 0 ?? false
  const renderSide = sideCardsData || props.sideContent
  const classList = [`Content`]

  if (props.contentModifier) {
    classList.push(`Content--${props.contentModifier}`)
  }

  if (renderSide) {
    classList.push(`has-side`)
  }

  const ContentSide = (
    <aside className='Content-side'>
      <div className='Content-sideSticky'>
        <div children={props.sideContent}></div>
        {sideCardsData && <WidgetBuilder widgetBuilderData={props.sideCards} />}
      </div>
    </aside>
  )

  return (
    <div className={classList.join(` `)}>
      <div className='Content-main'>{props.children}</div>
      {renderSide && ContentSide}
    </div>
  )
}

export default PuxContent

import './AllFeaturesTable.scss'

import React, { FunctionComponent, lazy, Suspense } from 'react'

import { PricingFeatureCategoryType } from './PricingPage'
const Tooltip = lazy(() => import(`@material-ui/core/Tooltip`))
interface PlansTableType {
  allFeatures: [PricingFeatureCategoryType]
}

const PRICING_PLANS = [`Essential`, `Business`, `Platform`, `Enterprise`]

const AllFeatures: FunctionComponent<PlansTableType> = (props) => {
  const handleClick = (event) => {
    let id = event.currentTarget.id;
    let tables = document.querySelectorAll(`.AllFeatures-mobile-item-table--${id}`);
    tables.forEach(function (table) {
      table.classList.toggle("opened");
    });
  };
  return (
    <div className={`AllFeatures`}>
      <div className={`AllFeatures-desktop`}>
        {props.allFeatures.map((item) => (
          <table key={item.contentItemId}>
            <tbody>
              <tr>
                <th>{item.displayText}</th>
                {PRICING_PLANS.map((plan) => (
                  <th key={plan}>
                    {plan === `Essential` ? `Essentials` : plan}
                  </th>
                ))}
              </tr>
              {item.pricingFeatureCategoryItems?.contentItems?.map(
                (feature, idx) => (
                  <tr key={idx}>
                    <td>
                      <div>
                        {feature.displayText}
                        {feature.pricingFeatureTooltip && (
                          <Suspense fallback={<></>}>
                            <Tooltip
                              title={feature.pricingFeatureTooltip}
                              placement='top'
                            >
                              <div className='MuiTooltip-tooltip'></div>
                            </Tooltip>
                          </Suspense>
                        )}
                      </div>
                    </td>
                    {PRICING_PLANS.map((plan) => (
                      <td key={plan}>
                        {(feature as any)[`pricingFeaturePlan${plan}`] && (
                          <div className='check-icon'></div>
                        )}
                        {!(feature as any)[`pricingFeaturePlan${plan}`] &&
                        (feature as any)[`pricingFeaturePlan${plan}Input`] &&
                        (feature as any)[`pricingFeaturePlan${plan}Input`].html && (
                          <div className='justify-center' dangerouslySetInnerHTML={{__html: (feature as any)[`pricingFeaturePlan${plan}Input`].html}}></div>
                        )}
                      </td>
                    ))}
                  </tr>
                )
              )}
            </tbody>
          </table>
        ))}
      </div>

      <div className={`AllFeatures-mobile`}>
        {props.allFeatures.map((item, index) => {
          return item.pricingFeatureCategoryItems?.contentItems?.map(
            (feature, idx) => (
              <>
                {idx == 0 ? <h3 id={`${index}`} onClick={handleClick}>{item.displayText}</h3> : null}
                <table
                  key={idx}
                  className={`AllFeatures-mobile-item-table--${index} AllFeatures-mobile-item ${idx ==
                    item.pricingFeatureCategoryItems?.contentItems.length - 1
                    ? `AllFeatures-mobile-item--without-border`
                    : ``
                    }`}
                >
                  <tbody>
                    <tr>
                      <th>{feature.displayText}</th>
                      <th></th>
                    </tr>
                    {PRICING_PLANS.map((plan) => (
                      <tr key={plan}>
                        <td>{plan}</td>
                        <td>
                          {(feature as any)[`pricingFeaturePlan${plan}`] && (
                            <div className='check-icon'></div>
                          )}
                          {!(feature as any)[`pricingFeaturePlan${plan}`] &&
                          (feature as any)[`pricingFeaturePlan${plan}Input`] &&
                          (feature as any)[`pricingFeaturePlan${plan}Input`].html && (
                            <div className='justify-center' dangerouslySetInnerHTML={{__html: (feature as any)[`pricingFeaturePlan${plan}Input`].html}}></div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )
          )
        })}
      </div>
    </div>
  )
}

export default AllFeatures

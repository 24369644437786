import React, { FunctionComponent } from 'react'
import { WidgetBase } from 'Shared/components/builders/WidgetBuilder'
import WidgetContainer from 'Shared/components/builders/WidgetContainer'

import EasySoftwareTestimonials, {
  EasySoftwareTestimonialsType,
} from '../EasySoftwareTestimonials/EasySoftwareTestimonials'

export interface WidgetEasySoftwareTestimonialsType
  extends WidgetBase,
  EasySoftwareTestimonialsType {
  contentType: 'EasySoftwareTestimonials'
}

const WidgetEasySoftwareTestimonials: FunctionComponent<WidgetEasySoftwareTestimonialsType> = (
  props
) => (
  <WidgetContainer
    {...props.puxWidgetProperties}
    {...props.puxWidgetAnimation}
    {...props.metadata}
    cropped
  >
    <EasySoftwareTestimonials
      displayText={props.displayText}
      testimonialsList={props.testimonialsList}
      contentItemId={props.contentItemId}
    />
  </WidgetContainer>
)

export default WidgetEasySoftwareTestimonials

import { useState, useEffect } from 'react'

export function useEditorStatus() {
    const [isEditor, setIsEditor] = useState(false);
    useEffect(() => {
        if (typeof window !== "undefined") {
            if (readEditorCookie("EasySoftwareEditMode") === "true") {
                setIsEditor(true);
                return;
            } else {
                setIsEditor(false);
                return;
            }
        }
    }, []);
    return isEditor;
}

function readEditorCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }

    return null;
}

export function resetEditorCookie() {
    document.cookie = `EasySoftwareEditMode=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
}

export function addEditorCookie() {
    var date = new Date();
    date.setTime(date.getTime() + 31 * 24 * 60 * 60 * 1000)
    var dateString = date.toLocaleDateString();
    document.cookie = `EasySoftwareEditMode=true` + `; expires=` + dateString + `;SameSite=Lax;`;
}
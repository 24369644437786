import React, { FunctionComponent } from 'react'
import { WidgetBase } from 'Shared/components/builders/WidgetBuilder'
import WidgetContainer from 'Shared/components/builders/WidgetContainer'

import EasySoftwareTable, {
  EasySoftwareTableType,
} from '../EasySoftwareTable/EasySoftwareTable'

export interface WidgetEasySoftwareTableType
  extends WidgetBase,
  EasySoftwareTableType {
  contentType: 'EasySoftwareTable'
}

const WidgetEasySoftwareTable: FunctionComponent<WidgetEasySoftwareTableType> = (
  props
) => (
  <WidgetContainer
    {...props.puxWidgetProperties}
    {...props.puxWidgetAnimation}
    {...props.metadata}
  >
    <EasySoftwareTable
      displayText={props.displayText}
      tableContent={props.tableContent}
      pageContentItemId={props.pageContentItemId}
      editorPath={props.editorPath}
      contentItemId={props.contentItemId}
    />
  </WidgetContainer>
)

export default WidgetEasySoftwareTable

import './PlansTable.scss'

import React, { FunctionComponent, lazy, Suspense, useEffect } from 'react'
import PuxButton from 'Shared/components/PuxButton/PuxButton'
import EasySoftwareEditorContainer from 'Root/Shared/components/EasySoftwareEditorContainer/EasySoftwareEditorContainer'
import {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'
import { getIsoCodesWithPrefixPublic } from 'Shared/utils/localeURL'
import { t } from 'ttag'

import { PricingCurrencyType, PricingFeatureType } from './PricingPage'
import { getModalTriggerId } from 'Root/Shared/components/PuxModal/PuxModal'

const Tooltip = lazy(() => import(`@material-ui/core/Tooltip`))

export interface ContentPickerType {
  internal: boolean[]
  text: string[]
  url: string[]
}

interface PlansTableType {
  currencies: [PricingCurrencyType]
  features: [PricingFeatureType]
  enterpriseContent: PuxWysiwygHtmlBodyType
  buyLink: ContentPickerType
  locale?: string,
  editorPath: string,
  pageContentItemId: string,
  wysiwygId: string
}
const project: string = process.env.PROJECT
  ? process.env.PROJECT
  : `EasyRedmineGatsby`
const PRICING_PLANS = [`Essential`, `Business`, `Platform`]

const isoCode = getIsoCodesWithPrefixPublic(false, project).filter(
  (iso) => iso.locale === process.env.LOCALE
)[0].isoCode ?? `en-GB`

const PlansTable: FunctionComponent<PlansTableType> = (props) => {
  const [currencyIdx, setCurrencyIdx] = React.useState(0)

  const getPrice = (currency: PricingCurrencyType, plan: string) => {
    const price = (currency as any)[`pricingCurrencyPrice${plan}`]
    const number = new Intl.NumberFormat(isoCode, {
      style: `currency`,
      currency: currency.displayText.toUpperCase(),
    }).formatToParts(price)

    const numberInteger: string[] = []
    const numberFraction: string[] = []
    const numberCurrency: string[] = []
    let numberCurrencyLast = true

    number.map((part, idx) => {
      const type = part.type
      if (type === `integer` || type === `group`) {
        numberInteger.push(part.value)
      }

      if (type === `fraction`) {
        if (part.value !== `00`) numberFraction.push(part.value)
      }

      if (type === `literal` || type === `currency`) {
        if (type === `currency` && idx === 0) {
          numberCurrencyLast = false
        }
        numberCurrency.push(part.value.replace(`US`, ``))
      }
    })

    return (
      <p className={`PlansTable-price-value`}>
        {numberCurrencyLast ? `` : numberCurrency}
        {numberInteger.length ? numberInteger.join(``) : ``}
        <sup>{numberFraction.length ? numberFraction.join(``) : ``}</sup>
        {numberCurrencyLast ? numberCurrency : ``}
      </p>
    )
  }

  const getCell = (item: PricingFeatureType, domain: string) => {
    const available: boolean = (item as any)[`pricingFeaturePlan${domain}`]
    const text = (item as any)[`pricingFeaturePlan${domain}Input`] ? (item as any)[`pricingFeaturePlan${domain}Input`].html : null

    return (
      <p
        className={`PlansTable-cell${available || text ? `` : ` empty`}`}
        key={item.contentItemId}
      >
        {available ? (
          <span className={`PlansTable-cell-label check-icon`}>
            {item.displayText}
          </span>
        ) : null}
        {!available && text ? (
          <>
            <div className={`PlansTable-cell-label`}>
              {item.displayText}
            </div>
            <div className={`PlansTable-cell-text`} dangerouslySetInnerHTML={{ __html: text }}></div>
          </>
        ) : undefined}
      </p>
    )
  }

  const getDefaultCurrency = () => {
    const cookie = document.cookie.match(`(^|;)\\s*` + `awscountry` + `\\s*=\\s*([^;]+)`)
    if (cookie) {
      props.currencies.map((currency, currencyIndex) => {
        const countries = currency.pricingCurrencyDefaultInCountriesList ?? []
        countries?.map((country) => {
          country === cookie[2] ? setCurrencyIdx(currencyIndex) : null
        })
      })
    }
  }

  useEffect(() => {
    getDefaultCurrency()
  }, [])

  const getCurrencySelector = (currencyIdx: number) => {
    return (
      <select
        className='PuxSelect'
        onChange={(event) => setCurrencyIdx(Number(event.target.value))}
      >
        {props.currencies.map((item, idx) => (
          <option
            key={item.displayText}
            value={idx}
            selected={currencyIdx === idx}
          >
            {item.displayText} ({item.pricingCurrencySymbol})
          </option>
        ))}
      </select>
    )
  }

  const openModal = (event) => {
    event.preventDefault()
    const trigger = document.getElementById(
      getModalTriggerId("talk")
    )
    if (trigger) {
      trigger.click()
    } else {
      console.log(
        `Modal error: Section-modal with ID "talk" not found`
      )
    }
  }
  
  return (
    <div className={`PlansTable`}>
      <div className='PlansTable-header'>
        <div className={`PlansTable-switch`}>
          <div className={`Button`}>Cloud</div>
          <PuxButton
            buttonLink={{
              url: [props.cta ? props.cta.url : `#server`],
              internal: [false],
              text: [props.cta ? props.cta.text : t`On-premises`],
            }}
            buttonType={`Button--primary`}
            buttonSize={`Button--large`}
            buttonColor={`Button--yellow`}
          />
        </div>
        <div className={`PlansTable-currency`}>
          {getCurrencySelector(currencyIdx)}
        </div>
      </div>
      <div className={`PlansTable-columns`}>
        <div className={`PlansTable-column PlansTable-column-label`}>
          <div className={`PlansTable-sticker-placeholder`}>&nbsp;</div>
          <div className={`PlansTable-price PlansTable-price-dummy`}>
            <h4>&nbsp;</h4>

            <p className={`PlansTable-price-value`}>&nbsp;</p>
            <p className='PlansTable-amount'>&nbsp;</p>
          </div>
          {props.features.map((item) => (
            <p key={item.contentItemId} className={`PlansTable-cell`}>
              {item.displayText}
              <Suspense fallback={<div>Loading...</div>}>
                <Tooltip title={item.pricingFeatureTooltip} placement='top'>
                  <div className='MuiTooltip-tooltip'></div>
                </Tooltip>
              </Suspense>
            </p>
          ))}
        </div>

        {PRICING_PLANS.map((plan) => (
          <div
            className={`PlansTable-column PlansTable-column-${plan.toLowerCase()}`}
            key={plan}
          >
            <div
              className={
                plan === `Business`
                  ? `PlansTable-sticker`
                  : `PlansTable-sticker-placeholder`
              }
            >
              <span>{t`Redmine.Pricing.MostPopular`}</span>
            </div>
            <div className={`PlansTable-price`}>
              <h4>
                {plan === `Essential` ? `Essentials` : plan}
                <div
                  className={
                    plan === `Business`
                      ? `PlansTable-sticker`
                      : `PlansTable-sticker-placeholder`
                  }
                >
                  <span>{t`Redmine.Pricing.MostPopular`}</span>
                </div>
              </h4>
              <div className='PlansTable-price-inner'>
                {getPrice(props.currencies[currencyIdx], plan)}
                <p className={`PlansTable-amount`}>
                  {t`Redmine.Pricing.UserPerMonth`}
                </p>
              </div>
            </div>
            {props.features.map((item) => getCell(item, plan))}
            <div className={`PlansTable-cta`}>
              <PuxButton
                buttonType={
                  plan === `Business` ? `Button--primary` : `Button--secondary`
                }
                buttonSize={`Button--medium`}
                buttonColor={
                  plan === `Business` ? `Button--yellow` : `Button--blue`
                }
                buttonLink={{
                  url: [`#trial`],
                  internal: [false],
                  text: [t`Redmine.Pricing.TryIt`],
                }}
              />
              <PuxButton
                buttonType='Button--link'
                buttonSize='Button--medium'
                buttonColor={
                  plan === `Business` ? `Button--white` : `Button--blue`
                }
                buttonLink={{
                  url: [
                    `${props.buyLink.url[0]
                    }/?pricing.plan=${plan.toLowerCase()}&pricing.currency=${props.currencies[
                      currencyIdx
                    ].displayText.toUpperCase()}&pricing.lang=${props.locale ?? "en-gb"}`,
                  ],
                  internal: [props.buyLink.internal[0]],
                  text: [props.buyLink.text[0]],
                }}
              />
            </div>
          </div>
        ))}

        <div className={`PlansTable-column PlansTable-column-enterprise`}>
          <div className={`PlansTable-sticker-placeholder`}></div>
          <div className={`PlansTable-price`}>
            <h4>{t`Redmine.Pricing.Enterprise`}</h4>
            <div className='PlansTable-price-inner'>
              <a className='PlansTable-price-value PlansTable-price-value--text' data-open-modal-id="talk" onClick={openModal}>{t`Redmine.Pricing.Enterprice.ContactUs`}</a>
              <p className={`PlansTable-amount`}>
                {t`Redmine.Pricing.UserPerMonth`}
              </p>
            </div>
          </div>
          <div className={`PlansTable-enterprise-content`}>
            <EasySoftwareEditorContainer wysiwygId={props.wysiwygId} content={props.enterpriseContent} pageContentItemId={props.pageContentItemId} editorPath={props.editorPath} />
          </div>
          <div className={`PlansTable-cta`}>
            <PuxButton
              buttonType='Button--secondary'
              buttonSize='Button--medium'
              buttonColor='Button--blue'
              buttonLink={{
                url: [`/`],
                internal: [true],
                text: [t`Redmine.Pricing.LetsTalk`],
              }}
              buttonOpenModalId='talk'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlansTable

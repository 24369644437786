import React, { FunctionComponent } from 'react'

import PuxPicture, { MediaSelectorImageType } from './PuxPicture'
export interface PuxMediaSelectorType {
  mediaSelectorImage: MediaSelectorImageType
  mediaSelectorLightbox?: boolean
  width?: string | number
  height?: string | number
  disableLazyload?: boolean
}

const PuxMediaSelector: FunctionComponent<PuxMediaSelectorType> = (props) => {
  return (
    <PuxPicture
      {...props.mediaSelectorImage}
      width={props.width}
      height={props.height}
      lightbox={props.mediaSelectorLightbox}
      disableLazyload={props.disableLazyload}
    />
  )
}

export default PuxMediaSelector

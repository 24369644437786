import OnModalInitEn from './MauticOnModalInit/OnModalInitEn'
import OnRouteChangeEn from './MauticOnRouteChange/OnRouteChangeEn'
import OnWebLoadEn from './MauticOnWebLoad/OnWebLoadEn'

export const MauticOnWebLoad = (): void => {
  return OnWebLoadEn()
}

export const MauticOnRouteChange = (): void => {
  return OnRouteChangeEn()
}

export const MauticOnModalInit = (context: HTMLElement): void => {
  return OnModalInitEn(context)
}

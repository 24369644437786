import React, { FunctionComponent, useState } from 'react'

export interface HomeIconType {
  icon: 'HomeIcon'
  options?: HomeIconPropsType
}

interface HomeIconPropsType {
  className?: string
  color: HomeIconStateType
  hoverColor: HomeIconStateType
}

interface HomeIconStateType {
  colorPrimary: string
}

const HomeIcon: FunctionComponent<HomeIconPropsType> = (props) => {
  const defaultTheme = props.color
  const hoverTheme = props.hoverColor
  const [colorTheme, setColorTheme] = useState<HomeIconStateType>(
    defaultTheme
  )

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10 10"
      className={props.className?.toLowerCase()}
      onMouseEnter={() => setColorTheme(hoverTheme)}
      onMouseLeave={() => setColorTheme(defaultTheme)}>
      <path
        fill={colorTheme.colorPrimary}
        d="M9.71,4.29l-4-4a1,1,0,0,0-1.42,0l-4,4A1,1,0,0,0,.08,5.38,1,1,0,0,0,1,6V9a1,1,0,0,0,1,1H8A1,1,0,0,0,9,9V6a1,1,0,0,0,.92-.62A1,1,0,0,0,9.71,4.29ZM7,5V8H3V5a.94.94,0,0,0-.12-.47L5,2.41,7.12,4.53A.94.94,0,0,0,7,5Z" />
    </svg>
  )
}

export default HomeIcon

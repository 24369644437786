import React, { FunctionComponent } from 'react'
import PuxAnchor from 'Shared/components/PuxAnchor/PuxAnchor'
import { t } from 'ttag'

import circles from '../../images/Circle.svg'
import laptop from '../../images/laptop.png'
import SignUpForm from '../MauticForms/MauticTrialForm'

const PuxNewsletterForm: FunctionComponent = () => {
  return (
    <PuxAnchor id='trial'>
      <div className='MautictrialForm'>
        <div className='MautictrialForm-container'>
          <div className='MautictrialForm-text'>
            <h2 className='MautictrialForm-title'>
              {t`EasySoftware.Newsletter.Title`}
            </h2>
            <p className='MautictrialForm-perex perex'>
              {t`EasySoftware.Newsletter.Perex`}
            </p>
            <SignUpForm specificID='newsletter-widget' />
          </div>
          <div className='MautictrialForm-image'>
            <div className='circles'>
              <img src={circles} />
            </div>
            <img className='laptop' src={laptop} />
          </div>
        </div>
      </div>
    </PuxAnchor>
  )
}

export default PuxNewsletterForm

import './EasySoftwareCards.scss'

import React, { FunctionComponent } from 'react'

import EasySoftwareCardItem, {
  EasySoftwareCardItemType,
} from './EasySoftwareCardItem'
import EasySoftwareProductCard, {
  EasySoftwareProductCardType,
} from './EasySoftwareProductCard'

export enum CardHeadingColor {
  GRAY = `gray`,
  RED = `red`,
}

// TODO: contentItems: any
export interface EasySoftwareCardsType {
  displayText: string
  cardsList: {
    contentItems: any
  }
  headingsColor: CardHeadingColor,
  pageContentItemId: string
  editorPath: string
}

const EasySoftwareCards: FunctionComponent<EasySoftwareCardsType> = (props) => (
  <div className='Cards'>
    <div className='Cards-items'>
      {props.cardsList &&
        props.cardsList.contentItems &&
        props.cardsList.contentItems.map((item: any) => {
          switch (item.contentType) {
            case `EasySoftwareCardItem`:
              return (
                <EasySoftwareCardItem
                  data={item}
                  key={item.contentItemId}
                  headingsColor={props.headingsColor}
                  pageContentItemId={props.pageContentItemId}
                  editorPath={
                    props.editorPath +
                    `,cid-${item.contentItemId},easySoftwareCardItem,cardItemPerex,html`
                  }
                />
              )
            case `EasySoftwareProductCard`:
              return (
                <EasySoftwareProductCard
                  data={item}
                  key={item.contentItemId}
                  headingsColor={props.headingsColor}
                  pageContentItemId={props.pageContentItemId}
                  editorPath={
                    props.editorPath +
                    `,cid-${item.contentItemId},easySoftwareProductCard,productCardPerex,html`
                  }
                />
              )
            default:
              return ``
          }
        })}
    </div>
  </div>
)

export default EasySoftwareCards

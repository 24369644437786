import './TestimonialPart.scss'

import React, { FunctionComponent } from 'react'

import EasySoftwareTestimonialItem, {
  EasySoftwareTestimonialType,
} from '../../EasySoftwareTestimonials/EasySoftwareTestimonialItem'

export interface TestimonialPartType extends EasySoftwareTestimonialType {
  contentType: 'EasySoftwareTestimonial'
}

const rootClass = `testimonial-part`

const PartnerPart: FunctionComponent<TestimonialPartType> = (props) => {
  return (
    <div className={`pux-repeater-item ${rootClass}-wrapper`}>
      <div className={`${rootClass}-item`}>
        <EasySoftwareTestimonialItem {...props} />
      </div>
    </div>
  )
}

export default PartnerPart

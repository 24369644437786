import './PuxSocials.scss'

import React, { FunctionComponent } from 'react'

interface PuxSocialsType {
  urlFacebook?: string
  urlTwitter?: string
  urlLinkedin?: string
  urlYoutube?: string
}

const PuxSocials: FunctionComponent<PuxSocialsType> = (props) => {
  return (
    <ul className='PuxSocial'>
      {props.urlFacebook && (
        <li>
          <a
            className='PuxSocial-logo PuxSocial-facebook'
            href={props.urlFacebook}
            target='_blank'
            rel='noopener'
          ></a>
        </li>
      )}
      {props.urlTwitter && (
        <li>
          <a
            className='PuxSocial-logo PuxSocial-twitter'
            href={props.urlTwitter}
            target='_blank'
            rel='noopener'
          ></a>
        </li>
      )}
      {props.urlLinkedin && (
        <li>
          <a
            className='PuxSocial-logo PuxSocial-linkedin'
            href={props.urlLinkedin}
            target='_blank'
            rel='noopener'
          ></a>
        </li>
      )}
      {props.urlYoutube && (
        <li>
          <a
            className='PuxSocial-logo PuxSocial-youtube'
            href={props.urlYoutube}
            target='_blank'
            rel='noopener'
          ></a>
        </li>
      )}
    </ul>
  )
}

export default PuxSocials

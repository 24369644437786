import React, { FunctionComponent } from 'react'
import { WidgetBase } from 'Shared/components/builders/WidgetBuilder'
import WidgetContainer from 'Shared/components/builders/WidgetContainer'

import PuxLinkGalleryWrapper, {
  PuxLinkGalleryWrapperType,
} from '../PuxGallery/PuxLinkGalleryWrapper'

export interface WidgetPuxLinkGalleryWrapperType
  extends WidgetBase,
  PuxLinkGalleryWrapperType {
  contentType: 'PuxLinkGalleryWrapper'
}

const WidgetPuxLinkGalleryWrapper: FunctionComponent<WidgetPuxLinkGalleryWrapperType> = (
  props
) => (
  <WidgetContainer
    {...props.puxWidgetProperties}
    {...props.puxWidgetAnimation}
    {...props.metadata}
  >
    <PuxLinkGalleryWrapper
      linkGalleryWrapperItemsSet={props.linkGalleryWrapperItemsSet}
      linkGalleryWrapperSlider = {props.linkGalleryWrapperSlider} 
    />
  </WidgetContainer>
)

export default WidgetPuxLinkGalleryWrapper

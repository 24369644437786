import '../EasySoftwareSearch/EasySoftwareSearch.scss'

import React, { FunctionComponent } from 'react'
import { searchConfig } from 'Root/puxconfig'
import { t } from 'ttag'

interface EasySoftwareSearchProps {
  searchType?: 'header' | 'headerRespo' | 'knowledgebase'
}

const GoogleSearch: FunctionComponent<EasySoftwareSearchProps> = (props) => {
  const searchClass =
    `Search--` +
    props?.searchType?.charAt(0)?.toLowerCase() +
    props?.searchType?.slice(1)

  const LOCALE = process.env.LOCALE ? process.env.LOCALE : `en`
  const PROJECT = process.env.PROJECT
    ? process.env.PROJECT
    : `EasyRedmineGatsby`

  const isKnowledeBase = props.searchType === `knowledgebase`

  const searchTriggerId = isKnowledeBase
    ? `knowledgeBaseSearchWidgetTrigger`
    : `searchWidgetTrigger`

  const configId = isKnowledeBase
    ? searchConfig[PROJECT]?.localizedOptions[LOCALE]?.knowledgeBaseConfigId
    : searchConfig[PROJECT]?.localizedOptions[LOCALE]?.configId

  return configId ? (
    <>
      <gen-search-widget
        configId={configId}
        triggerId={searchTriggerId}
      ></gen-search-widget>

      <div className={`Search ${searchClass}`}>
        {props.searchType === `header` && (
          <div className={`Search-icon`} id={searchTriggerId}></div>
        )}
        <div className='Search-wrapper'>
          <input
            className='Search-input'
            type='text'
            id={searchTriggerId}
            placeholder={
              props?.searchType === `header` ||
              props?.searchType === `headerRespo`
                ? t`Redmine.Search.Placeholder`
                : t`Redmine.KnowledgeBase.Search.Placeholder`
            }
          />
        </div>
      </div>
    </>
  ) : null
}

export default GoogleSearch

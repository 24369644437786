import React from 'react'

import { keyframes } from '@emotion/react'
// import Slide from 'react-awesome-reveal'
import Reveal from 'react-awesome-reveal'

export function getAnimation(animationType: string) {
  switch (animationType) {
    case `fade-in`:
      return keyframes`
          from {
            opacity: 0;
          }
          
          to {
            opacity: 1;
          }
        `

    case `fade-from-top`:
      return keyframes`
          from {
            opacity: 0;
            transform: translate(0, -200px);
          }
          
          to {
            opacity: 1;
            transform: translate(0, 0);
          }
        `

    case `fade-from-left`:
      return keyframes`
          from {
            opacity: 0;
            transform: translate(-200px, 0);
          }
          
          to {
            opacity: 1;
            transform: translate(0, 0);
          }
        `

    case `fade-from-right`:
      return keyframes`
          from {
            opacity: 0;
            transform: translate(200px, 0);
          }
          
          to {
            opacity: 1;
            transform: translate(0, 0);
          }
        `

    case `fade-in-up`:
      return keyframes`
          from {
            opacity: 0;
            -webkit-transform: translate3d(0,100%,0);
            transform: translate3d(0,100%,0)
          }
      
          to {
            opacity: 1;
            -webkit-transform: translateZ(0);
            transform: translateZ(0)
          }
        `

    case `slide-in-up`:
      return keyframes`
          from {
            -webkit-transform: translate3d(0,100%,0);
            transform: translate3d(0,100%,0);
            visibility: visible
          }
      
          to {
            -webkit-transform: translateZ(0);
            transform: translateZ(0)
          }
        `

    default:
      return null
  }
}

export function getAnimationWrapper(widgetChildren, isEnabled, widgetAnimation, animationFraction: number | string = 0, animationDuration: number | string = 500, animationDelay: number | string = 150) {
  if (isEnabled && widgetAnimation) {
    return (
      <Reveal
        keyframes={widgetAnimation}
        triggerOnce
        fraction={Number(animationFraction) <= 1 ? Number(animationFraction) : 1}
        duration={Number(animationDuration)}
        delay={Number(animationDelay)}
        style={{
          transitionTimingFunction: `cubic-bezier(.25,.46,.45,.94)`,
          animationFillMode: `backwards`,
        }}
      >
        {widgetChildren}
      </Reveal>
    )
  } else {
    return widgetChildren
  }
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
export default () => {
  // set onsubmit attribute to prevent "double" initialization
  // set up element needed for ajax submit
  if (typeof window.MauticFormValidations != `undefined`) {
    window.MauticFormsInitiated = Object.keys(MauticFormValidations)
    for (let i = 0, n = window.MauticFormsInitiated.length; i < n; i++) {
      const formId = window.MauticFormsInitiated[i]
      const theForm = document.getElementById(`mauticform_` + formId)
      const theFormMessenger = document.getElementById(
        `mauticform_` + formId + `_messenger`
      )
      if (theForm && !theFormMessenger) {
        theForm.setAttribute(
          `onsubmit`,
          `event.preventDefault(); MauticSDK.validateForm('` +
          formId +
          `', true);`
        )
        theForm.target = `mauticiframe_` + formId
        if (!document.getElementById(`mauticform_` + formId + `_messenger`)) {
          const messengerInput = document.createElement(`INPUT`)
          messengerInput.type = `hidden`
          messengerInput.setAttribute(`name`, `mauticform[messenger]`)
          messengerInput.setAttribute(
            `id`,
            `mauticform_` + formId + `_messenger`
          )
          messengerInput.value = 1
          theForm.appendChild(messengerInput)
        }
      }
    }
  }
}

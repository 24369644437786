import React, { FunctionComponent } from 'react'

interface CircleType {
  radius: number
  stroke: number
  progress: number
  customClass: string
}

const Circle: FunctionComponent<CircleType> = (props) => {
  const normalizedRadius = props.radius - props.stroke * 2
  const circumference = normalizedRadius * 2 * Math.PI

  const getValidProgress = (progress: number): number => {
    if (progress >= 0 && progress <= 100) {
      return progress
    } else {
      return 100
    }
  }

  const strokeDashoffset =
    circumference - (getValidProgress(props.progress) / 100) * circumference

  return (
    <svg
      className={props.customClass}
      height={props.radius * 2}
      width={props.radius * 2}
    >
      <circle
        fill='transparent'
        strokeWidth={props.stroke}
        strokeDasharray={circumference + ` ` + circumference}
        style={{
          strokeDashoffset: strokeDashoffset,
        }}
        r={normalizedRadius}
        cx={props.radius}
        cy={props.radius}
      />
    </svg>
  )
}

export default Circle

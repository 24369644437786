export const getUrlWithProtocol = (input: string): string | null => {
  if (typeof input !== `string`) {
    return null
  }

  const urlWithProtocol =
    input.indexOf(`http://`) < 0 && input.indexOf(`https://`) < 0
      ? `http://${input}`
      : input

  return urlWithProtocol.replace(`////`, `//`).replace(`///`, `//`)
}

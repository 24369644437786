import React from 'react'

import ArrowRightIcon, { ArrowRightIconType } from './Icons/ArrowRightIcon'
import CheckIcon, { CheckIconType } from './Icons/CheckIcon'
import ChevronIcon, { ChevronIconType } from './Icons/ChevronIcon'
import CloseIcon, { CloseIconType } from './Icons/CloseIcon'
import DownloadIcon, { DownloadIconType } from './Icons/DownloadIcon'
import FacebookIcon, { FacebookIconType } from './Icons/FacebookIcon'
import HomeIcon, { HomeIconType } from './Icons/HomeIcon'
import InfoIcon, { InfoIconType } from './Icons/InfoIcon'
import LinkedinIcon, { LinkedinIconType } from './Icons/LinkedinIcon'
import PinterestIcon, { PinterestIconType } from './Icons/PinterestIcon'
import PlayIcon, { PlayIconType } from './Icons/PlayIcon'
import PlusIcon, { PlusIconType } from './Icons/PlusIcon'
import SearchIcon, { SearchIconType } from './Icons/SearchIcon'
import SquareArrowIcon, { SquareArrowIconType } from './Icons/SquareArrowIcon'
import StarIcon, { StarIconType } from './Icons/StarIcon'
import TestimonialIcon, { TestimonialIconType } from './Icons/TestimonialIcon'
import TwitterIcon, { TwitterIconType } from './Icons/TwitterIcon'
import UserIcon, { UserIconType } from './Icons/UserIcon'
import YoutubeIcon, { YoutubeIconType } from './Icons/YoutubeIcon'

export type IconType =
  | ArrowRightIconType
  | CheckIconType
  | DownloadIconType
  | PlayIconType
  | PlusIconType
  | FacebookIconType
  | TwitterIconType
  | PinterestIconType
  | YoutubeIconType
  | LinkedinIconType
  | StarIconType
  | InfoIconType
  | SearchIconType
  | SquareArrowIconType
  | UserIconType
  | HomeIconType
  | TestimonialIconType
  | CloseIconType
  | ChevronIconType

const renderIcon = (icon: IconType) => {
  switch (icon.icon) {
    case `ArrowRightIcon`:
      return (
        <ArrowRightIcon
          className={icon.icon}
          color={
            icon.options?.color ?? {
              colorPrimary: `#2e82dc`,
            }
          }
          hoverColor={
            icon.options?.hoverColor ?? {
              colorPrimary: `#2e82dc`,
            }
          }
        />
      )

    case `CheckIcon`:
      return (
        <CheckIcon
          className={`pux-icon ${icon.icon}`}
          color={
            icon.options?.color ?? {
              colorPrimary: `#00e099`,
              colorSecondary: `#fff`,
            }
          }
          hoverColor={
            icon.options?.hoverColor ?? {
              colorPrimary: `#00e099`,
              colorSecondary: `#fff`,
            }
          }
        />
      )

    case `DownloadIcon`:
      return (
        <DownloadIcon
          className={icon.icon}
          color={
            icon.options?.color ?? {
              colorPrimary: `#2e82dc`,
            }
          }
          hoverColor={
            icon.options?.hoverColor ?? {
              colorPrimary: `#2e82dc`,
            }
          }
        />
      )

    case `SquareArrowIcon`:
      return (
        <SquareArrowIcon
          className={`pux-icon ${icon.icon}`}
          color={
            icon.options?.color ?? {
              colorPrimary: `#0080ff`,
              colorSecondary: `#fff`,
            }
          }
          hoverColor={
            icon.options?.hoverColor ?? {
              colorPrimary: `#ff1066`,
              colorSecondary: `#fff`,
            }
          }
        />
      )

    case `UserIcon`:
      return (
        <UserIcon
          className={`pux-icon ${icon.icon}`}
          color={
            icon.options?.color ?? {
              colorPrimary: `#0080ff`,
              colorSecondary: `#fff`,
            }
          }
          hoverColor={
            icon.options?.hoverColor ?? {
              colorPrimary: `#ff1066`,
              colorSecondary: `#fff`,
            }
          }
        />
      )

    case `SearchIcon`:
      return (
        <SearchIcon
          className={`pux-icon ${icon.icon}`}
          color={
            icon.options?.color ?? {
              colorPrimary: `#0080ff`,
              colorSecondary: `#fff`,
            }
          }
          hoverColor={
            icon.options?.hoverColor ?? {
              colorPrimary: `#ff1066`,
              colorSecondary: `#fff`,
            }
          }
        />
      )

    case `PlayIcon`:
      return (
        <PlayIcon
          className={`pux-icon ${icon.icon}`}
          color={
            icon.options?.color ?? {
              colorPrimary: `var(--Yellow3)`,
              colorSecondary: `var(--Text1)`,
            }
          }
          hoverColor={
            icon.options?.hoverColor ?? {
              colorPrimary: `var(--Yellow4)`,
              colorSecondary: `var(--Text1)`,
            }
          }
        />
      )

    case `FacebookIcon`:
      return (
        <FacebookIcon
          className={`pux-icon ${icon.icon}`}
          color={
            icon.options?.color ?? {
              colorPrimary: `#225899`,
              colorSecondary: `#fff`,
            }
          }
          hoverColor={
            icon.options?.hoverColor ?? {
              colorPrimary: `#ff1066`,
              colorSecondary: `#fff`,
            }
          }
        />
      )

    case `TwitterIcon`:
      return (
        <TwitterIcon
          className={`pux-icon ${icon.icon}`}
          color={
            icon.options?.color ?? {
              colorPrimary: `#34a9eb`,
              colorSecondary: `#fff`,
            }
          }
          hoverColor={
            icon.options?.hoverColor ?? {
              colorPrimary: `#ff1066`,
              colorSecondary: `#fff`,
            }
          }
        />
      )

    case `PinterestIcon`:
      return (
        <PinterestIcon
          className={`pux-icon ${icon.icon}`}
          color={
            icon.options?.color ?? {
              colorPrimary: `#ec2023`,
              colorSecondary: `#fff`,
            }
          }
          hoverColor={
            icon.options?.hoverColor ?? {
              colorPrimary: `#ff1066`,
              colorSecondary: `#fff`,
            }
          }
        />
      )

    case `YoutubeIcon`:
      return (
        <YoutubeIcon
          className={`pux-icon ${icon.icon}`}
          color={
            icon.options?.color ?? {
              colorPrimary: `#f22121`,
              colorSecondary: `#fff`,
            }
          }
          hoverColor={
            icon.options?.hoverColor ?? {
              colorPrimary: `#ff1066`,
              colorSecondary: `#fff`,
            }
          }
        />
      )

    case `LinkedinIcon`:
      return (
        <LinkedinIcon
          className={`pux-icon ${icon.icon}`}
          color={
            icon.options?.color ?? {
              colorPrimary: `#267abb`,
              colorSecondary: `#fff`,
            }
          }
          hoverColor={
            icon.options?.hoverColor ?? {
              colorPrimary: `#ff1066`,
              colorSecondary: `#fff`,
            }
          }
        />
      )

    case `StarIcon`:
      return (
        <StarIcon
          className={`pux-icon ${icon.icon}`}
          color={
            icon.options?.color ?? {
              colorPrimary: `#9aa9b8`,
            }
          }
          hoverColor={
            icon.options?.hoverColor ?? {
              colorPrimary: `#9aa9b8`,
            }
          }
        />
      )

    case `StarActiveIcon`:
      return (
        <StarIcon
          className={`pux-icon ${icon.icon}`}
          color={
            icon.options?.color ?? {
              colorPrimary: `#ffc000`,
            }
          }
          hoverColor={
            icon.options?.hoverColor ?? {
              colorPrimary: `#ffc000`,
            }
          }
        />
      )

    case `InfoIcon`:
      return (
        <InfoIcon
          className={`pux-icon ${icon.icon}`}
          color={
            icon.options?.color ?? {
              colorPrimary: `#dde0ee`,
            }
          }
          hoverColor={
            icon.options?.hoverColor ?? {
              colorPrimary: `#dde0ee`,
            }
          }
        />
      )

    case `HomeIcon`:
      return (
        <HomeIcon
          className={`pux-icon ${icon.icon}`}
          color={
            icon.options?.color ?? {
              colorPrimary: `#dde0ee`,
            }
          }
          hoverColor={
            icon.options?.hoverColor ?? {
              colorPrimary: `#dde0ee`,
            }
          }
        />
      )

    case `ArrowRightIcon`:
      return (
        <ArrowRightIcon
          className={icon.icon}
          color={
            icon.options?.color ?? {
              colorPrimary: `#2e82dc`,
            }
          }
          hoverColor={
            icon.options?.hoverColor ?? {
              colorPrimary: `#2e82dc`,
            }
          }
        />
      )

    case `TestimonialIcon`:
      return (
        <TestimonialIcon
          className={`pux-icon ${icon.icon}`}
          color={
            icon.options?.color ?? {
              colorPrimary: `#00c8aa`,
            }
          }
          hoverColor={
            icon.options?.hoverColor ?? {
              colorPrimary: `#00c8aa`,
            }
          }
        />
      )

    case `PlusIcon`:
      return (
        <PlusIcon
          className={icon.icon}
          color={
            icon.options?.color ?? {
              colorPrimary: `#50596f`,
            }
          }
          hoverColor={
            icon.options?.hoverColor ?? {
              colorPrimary: `#50596f`,
            }
          }
        />
      )

    case `CloseIcon`:
      return (
        <CloseIcon
          className={icon.icon}
          color={
            icon.options?.color ?? {
              colorPrimary: `#0080ff`,
            }
          }
          hoverColor={
            icon.options?.hoverColor ?? {
              colorPrimary: `#ff1066`,
            }
          }
        />
      )

    case `ChevronIcon`:
      return (
        <ChevronIcon
          className={icon.icon}
          color={
            icon.options?.color ?? {
              colorPrimary: `#0080ff`,
            }
          }
          hoverColor={
            icon.options?.hoverColor ?? {
              colorPrimary: `#ff1066`,
            }
          }
        />
      )

    default:
      return <svg className={`svg-not-found: ${icon.icon}`}></svg>
  }
}

export default function PuxIconBuilder(props: IconType): JSX.Element {
  return renderIcon(props)
}

import React, { FunctionComponent, useState } from 'react'

export interface FacebookIconType {
  icon: 'FacebookIcon'
  options?: FacebookIconPropsType
}

interface FacebookIconPropsType {
  className?: string
  color: FacebookIconStateType
  hoverColor: FacebookIconStateType
}

interface FacebookIconStateType {
  colorPrimary: string
  colorSecondary: string
}

const FacebookIcon: FunctionComponent<FacebookIconPropsType> = (props) => {
  const defaultTheme = props.color
  const hoverTheme = props.hoverColor
  const [colorTheme, setColorTheme] = useState<FacebookIconStateType>(
    defaultTheme
  )

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 30 30'
      width='100%'
      className={props.className?.toLowerCase()}
      onMouseEnter={() => setColorTheme(hoverTheme)}
      onMouseLeave={() => setColorTheme(defaultTheme)}
    >
      <g>
        <circle fill={colorTheme.colorPrimary} cx='15' cy='15' r='15' />
        <path
          fill={colorTheme.colorSecondary}
          d='M18.77,15.59H16.09v9.8H12v-9.8H10.11V12.14H12V9.91a3.8,3.8,0,0,1,4.09-4.09h3V9.18H17a.83.83,0,0,0-.86.94v2h3Z'
        />
      </g>
    </svg>
  )
}

export default FacebookIcon

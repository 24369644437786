import './CaseStudyPart.scss'

import React, { FunctionComponent } from 'react'
import { t } from 'ttag'

import PuxMediaSelector, {
  PuxMediaSelectorType,
} from 'Root/Shared/components/content/mediaImage/PuxMediaSelector'
import EasySoftwareEditorContainer from 'Root/Shared/components/EasySoftwareEditorContainer/EasySoftwareEditorContainer'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'
import PuxButton from '../../PuxButton/PuxButton'
import { getLocalizedUrl } from 'Shared/utils/localeURL'
import PuxLink from 'Shared/utils/PuxLink'
import { PuxKeyValueType } from 'Shared/queries/page-queries'

export interface CaseStudyPartType {
  contentType: 'EasySoftwareCaseStudy'
  contentItemId: string
  path: string
  displayText: string
  caseStudyPerex: PuxWysiwygHtmlBodyType
  caseStudyImage: PuxMediaSelectorType
  caseStudyParameters: {
    contentItems: [PuxKeyValueType]
  }
}

const CaseStudyPart: FunctionComponent<CaseStudyPartType> = (props) => {
  return (
    <div className='pux-repeater-item CasestudyPart-wrapper'>
      <div className='CasestudyPart-item'>
        <PuxLink to={`${getLocalizedUrl(props.path)}`} className='CasestudyPart-image'>
          <PuxMediaSelector {...props.caseStudyImage} />
        </PuxLink>

        <PuxLink to={`${getLocalizedUrl(props.path)}`}>
          <h3 className='CasestudyPart-title'>
            {props.displayText}
          </h3>
        </PuxLink>

        <div className='CasestudyPart-parameters'>
          {props.caseStudyParameters &&
            props.caseStudyParameters.contentItems &&
            props.caseStudyParameters.contentItems.map((parameter, idx) => (
              <div className='CaseStudyItem-parameter' key={idx}>
                <div className='CaseStudyItem-label'>{parameter.displayText}:</div>
                <PuxWysiwyg wysiwygId={props.contentItemId} inline content={parameter.keyValueContent} />
              </div>
            ))}
        </div>

        <div className='CasestudyPart-perex'>
          <PuxWysiwyg wysiwygId={props.contentItemId} content={props.caseStudyPerex} inline />
        </div>

        <PuxButton
          buttonType='Button--link'
          buttonSize='Button--large'
          buttonColor='Button--blue'
          buttonLink={{
            url: [props.path],
            internal: [true],
            text: [t`Redmine.CaseStudy.ReadMore`],
          }}
        />
      </div>
    </div>
  )
}

export default CaseStudyPart

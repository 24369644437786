import './EasySoftwareInfoBar.scss'

import React, { useEffect, useState } from 'react'
import { t } from 'ttag'

const EasySoftwareInfoBar = () => {
  const [visible, setVisible] = useState(false);

  const getInfoBarStatusFromCookies = () => {
    const cookie = document.cookie.match(new RegExp(`(^| )` + `info-bar-hidden` + `=([^;]+)`))
    if (cookie === null) {
      setVisible(true)
    }
  }

  useEffect(() => {
    getInfoBarStatusFromCookies()
  }, [])

  function hideInfoBar() {
    setVisible(false)
    let expires = ``;
    const date = new Date();
    date.setTime(date.getTime() + (365*24*60*60*1000));
    expires = `; expires=` + date.toUTCString();
    document.cookie = `info-bar-hidden` + `=` + (`true` || ``) + expires + `; path=/`;
  }

  if (!visible) {
    return null
  }

  return (
    <div className='InfoBar'>
      <div>
        <p dangerouslySetInnerHTML={
          {
            __html: t`Redmine.InfoBar.Text`
          }
        }>
        </p>
        <a href={t`Redmine.InfoBar.Link.Url`} dangerouslySetInnerHTML={
          {
            __html: t`Redmine.InfoBar.Link.Text`
          }
        }>
        </a>
      </div>
      <button onClick={() => hideInfoBar()}></button>
    </div >
  )
}

export default EasySoftwareInfoBar

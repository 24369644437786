export const removeAllParamsWhenUTM = (path: string): void => {
  const url = new URL(path)
  const searchParams = url.searchParams

  //bug fix for redirect loop when url = https://www.easyredmine.com/component/easyredminehelper?view=knowledge&category_id=4?utm_test=test
  const urlSplit = url.href.split(`?`)
  if (urlSplit.length > 2) {
    const newURL: string[] = []

    urlSplit.map((urlPart, i) => {
      if (i === 0) {
        return newURL.push(urlPart + `?`)
      }

      if (i === 1) {
        return newURL.push(urlPart)
      }

      return newURL.push(`&` + urlPart)
    })

    return window.location.replace(newURL.join(``))
  }

  if (
    searchParams.toString().includes(`utm_`) ||
    searchParams.toString().includes(`gclid`) ||
    searchParams.toString().includes(`aw_`)
  ) {
    const allParams: string[] = []
    for (const param of searchParams.keys()) {
      if (param.toString().includes(`utm_`) ||
        param.toString().includes(`gclid`) ||
        param.toString().includes(`aw_`)) {
        allParams.push(param)
      }
    }

    allParams.map((param) => {
      searchParams.delete(param)
    })

    return window.location.replace(url.href)
  }

  return
}

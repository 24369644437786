import React, { FunctionComponent } from 'react'
import { MediaSelectorImageType } from 'Root/Shared/components/content/mediaImage/PuxPicture'
import { WidgetBase } from 'Shared/components/builders/WidgetBuilder'
import WidgetContainer from 'Shared/components/builders/WidgetContainer'

import PuxGallery from '../PuxGallery/PuxGallery'
import { PuxGalleryItemType } from '../PuxGallery/PuxGalleryItem'

export interface PuxGallerySetType {
  contentType: 'PuxGallerySet'
  puxGallerySetSize: string
  gallerySetItems: {
    mediaSelectorImage: MediaSelectorImageType
    mediaSelectorTitle: {
      html: string
    }
  }
}

export interface PuxGalleryWrapperType {
  contentItemId?: string
  displayText: string
  galleryWrapperItemsToLightbox: boolean
  gallerySlider: boolean
  galleryWrapperItemsSet: {
    cultureContentItems: PuxGallerySetType[]
  }
}

export interface WidgetPuxGalleryWrapperType
  extends WidgetBase,
  PuxGalleryWrapperType {
  contentType: 'PuxGalleryWrapper'
}

const WidgetPuxGalleryWrapper: FunctionComponent<WidgetPuxGalleryWrapperType> = (
  props
) => {
  const inputData = props.galleryWrapperItemsSet?.cultureContentItems?.length > 0 ? props.galleryWrapperItemsSet?.cultureContentItems[0]?.gallerySetItems : null
  const text = inputData?.mediaSelectorTitle?.html
  const imageData = inputData?.mediaSelectorImage
  const setSize = props.galleryWrapperItemsSet?.cultureContentItems?.length > 0 ? props.galleryWrapperItemsSet?.cultureContentItems[0]?.puxGallerySetSize : ""

  if (inputData === null) {
    return null
  }

  const contentItems: PuxGalleryItemType[] = imageData?.resizePaths?.map(
    (mainPath, idx) => {
      return {
        alt: text,
        galleryItemImage: {
          resizePaths: [mainPath],
          resizePathsTablet: [imageData.resizePathsTablet[idx]],
          resizePathsMobile: [imageData.resizePathsMobile[idx]],
          texts: text,
        },
      }
    }
  )

  return (
    <WidgetContainer
      {...props.puxWidgetProperties}
      {...props.puxWidgetAnimation}
      {...props.metadata}
    >
      <PuxGallery
        displayText={props.displayText}
        galleryList={{ contentItems: contentItems }}
        galleryItemsToLightbox={props.galleryWrapperItemsToLightbox}
        gallerySlider={props.gallerySlider}
        gallerySize={setSize}
      />
    </WidgetContainer>
  )
}

export default WidgetPuxGalleryWrapper

import './EasySoftwareSecondaryNavigation.scss'

import React, { FunctionComponent } from 'react'
import PuxNavigation, {
  ContentLinkType,
} from 'Shared/components/PuxNavigation/PuxNavigation'

export interface NavigationLinkType {
  contentType?: string
  contentItemId?: string
  sideCardNavigationLinkItem: ContentLinkType
  sideCardNavigationLinkNewWindow: boolean
  sideCardNavigationLinkIcon: string
}

interface NavigationContentItems {
  contentItems: NavigationLinkType[]
}

export interface EasySoftwareSecondaryNavigationType {
  contentType: 'EasySoftwareSecondaryNavigation'
  secondaryNavigationTitle: string
  bag: NavigationContentItems
}

const EasySoftwareSecondaryNavigation: FunctionComponent<EasySoftwareSecondaryNavigationType> = (props) => {
  const data = props

  return (
    <div className='SecondaryNavigation'>
      <div className='SecondaryNavigation-title'>{props.secondaryNavigationTitle}</div>
      <PuxNavigation
        type={'widget'}
        items={props.bag?.contentItems}
      />
    </div>
  )
}


export default EasySoftwareSecondaryNavigation

import './EasySoftwareSideCard.scss'

import { useLocation } from '@reach/router'
import React, { FunctionComponent } from 'react'
import { PuxAnchorLink } from 'Shared/utils/PuxAnchorLink/PuxAnchorLink'

import background from '../../images/SideCard.svg'

import PuxWysiwyg, { PuxWysiwygHtmlBodyType } from '../PuxWysiwyg/PuxWysiwyg'

export interface EasySoftwareSideCardTrialType {
  contentType: 'EasySoftwareSideCardTrial'
  displayText: string
  contentItemId: string
  sideCardTrialText: PuxWysiwygHtmlBodyType
  sideCardTrialButtonText: string
  widgetOrder?: number
}

const EasySoftwareSideCardTrial: FunctionComponent<EasySoftwareSideCardTrialType> = (
  props
) => {
  return (
    <div className='Sidebar-box Sidebar-box--trial'>
      <img className='Sidebar-background' src={background} />
      <h3 className='Sidebar-box-title'>{props.displayText}</h3>
      <PuxWysiwyg
        className='Sidebar-box-perex'
        content={props.sideCardTrialText}
      />
      <PuxAnchorLink
        className='Button Button--primary Button--blue Button--large'
        to={`${useLocation().pathname ?? ``}#trial`}
      >
        {props.sideCardTrialButtonText}
      </PuxAnchorLink>
    </div>
  )
}

export default EasySoftwareSideCardTrial

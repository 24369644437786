// import 'Shared/components/PuxButton/PuxButton.scss'

// import React, { FunctionComponent } from 'react'

// import Layout from '../components/core/Layout'

// const styleGuide: FunctionComponent<any> = (props) => {
//   return (
//     <Layout>
//       <main>
//         <div className='Contact'>
//           <div className='Container'>
//             <div className='ContactSection'>
//               <div className='Contact-col'>
//                 <table>
//                   <thead>
//                     <tr>
//                       <th>Phone</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     <tr>
//                       <td>UK</td>
//                       <td>
//                         <a href='tel:+44 207 193 4089' className='Tel'>
//                           +44 207 193 4089
//                         </a>
//                       </td>
//                     </tr>
//                     <tr>
//                       <td>US</td>
//                       <td>
//                         <a href='tel:+1 415 800 4685' className='Tel'>
//                           +1 415 800 4685
//                         </a>
//                       </td>
//                     </tr>
//                     <tr>
//                       <td>PL</td>
//                       <td>
//                         <a href='tel:+48 717 166 008' className='Tel'>
//                           +48 717 166 008
//                         </a>
//                       </td>
//                     </tr>
//                     <tr>
//                       <td>RU</td>
//                       <td>
//                         <a href='tel:+7 495 185 1255' className='Tel'>
//                           +7 495 185 1255
//                         </a>
//                       </td>
//                     </tr>
//                     <tr>
//                       <td>CZ</td>
//                       <td>
//                         <a href='tel:+42 022 298 0481' className='Tel'>
//                           +42 022 298 0481
//                         </a>
//                       </td>
//                     </tr>
//                   </tbody>
//                 </table>
//               </div>
//               <div className='Email-col'>
//                 <p className='EmailHeading'>E-mail</p>
//                 <div className='Info'>
//                   <p>Information</p>
//                   <p>
//                     <a className='Email' href='mailto:info@easysoftware.com'>
//                       info@easysoftware.com
//                     </a>
//                   </p>
//                 </div>
//                 <div className='Support'>
//                   <p>Support</p>
//                   <p>
//                     <a className='Email' href='mailto:support@easysoftware.com'>
//                       support@easysoftware.com
//                     </a>
//                   </p>
//                 </div>
//               </div>
//               <div className='Social-col'>
//                 <p className='SocialHeading'>Follow us</p>
//                 <ul>
//                   <li>
//                     <a href='#'>LinkedIn</a>
//                   </li>
//                   <li>
//                     <a href='#'>Facebook</a>
//                   </li>
//                   <li>
//                     <a href='#'>Instagram</a>
//                   </li>
//                   <li>
//                     <a href='#'>Youtube</a>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//             <div className='AddressSection'>
//               <div className='Address-col'>
//                 <div className='AddressTitle'>Easy Software Group SE</div>
//                 <div className='Location'>
//                   <p>Jugoslávských partyzánů</p>
//                   <p>34 160 00 Prague Czech Republic</p>
//                 </div>
//                 <div className='Company'>
//                   <p>Company ID: 08939586</p>
//                 </div>
//               </div>
//               <div className='Address-col'>
//                 <div className='AddressTitle'>Easy Software s.r.o. (CZ)</div>
//                 <div className='Location'>
//                   <p>Jugoslávských partyzánů 34</p>
//                   <p>160 00 Prague</p>
//                   <p>Czech Republic</p>
//                 </div>
//                 <div className='Company'>
//                   <p>Company ID: 26426269</p>
//                   <p>VAT ID: CZ26426269</p>
//                 </div>
//                 <div className='Payment'>
//                   <p>Payment credentials</p>
//                   <p>Raiffeisenbank, a.s.</p>
//                   <p>Account number: 375842028/5500</p>
//                   <p>IBAN: CZ06 5500 0000 0003 7584 2028</p>
//                   <p>SWIFT: RZBCCZPP</p>
//                 </div>
//               </div>
//               <div className='Address-col'>
//                 <div className='AddressTitle'>Easy Software Ltd. (UK)</div>
//                 <div className='Location'>
//                   <p>Kemp House, 152-160 City Road</p>
//                   <p>EC1V 2NX London</p>
//                   <p>United Kingdom</p>
//                 </div>
//                 <div className='Company'>
//                   <p>Company ID: 08960980</p>
//                   <p>VAT ID: GB226711521</p>
//                 </div>
//                 <div className='Payment'>
//                   <p>Payment credentials</p>
//                   <p>Citfin, spořitelní družstvo</p>
//                   <p>Account number: 1109913/2060</p>
//                   <p>IBAN: CZ0320600000000001109913</p>
//                   <p>SWIFT: CITFCZPPXXX</p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </main>
//     </Layout>
//   )
// }

// export default styleGuide


import React, { FunctionComponent } from "react";

const styleGuide: FunctionComponent<any> = (props) => {
  return (
    <div></div>
  )
}
export default styleGuide;
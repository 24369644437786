import React, { FunctionComponent } from 'react'
import { getLocalizedUrl } from 'Root/Shared/utils/localeURL'
import PuxLink from 'Root/Shared/utils/PuxLink'
import { t } from 'ttag'

import PuxMediaSelector, {
  PuxMediaSelectorType,
} from '../content/mediaImage/PuxMediaSelector'
import PuxButton from '../PuxButton/PuxButton'
import PuxWysiwyg, { PuxWysiwygHtmlBodyType } from '../PuxWysiwyg/PuxWysiwyg'
import { PuxKeyValueType } from 'Shared/queries/page-queries'

export interface EasySoftwareCaseStudyItemType {
  contentItemId: string
  contentType: string // unused
  displayText: string
  path: string
  caseStudyPerex: PuxWysiwygHtmlBodyType
  caseStudyImage: PuxMediaSelectorType
  caseStudyParameters: {
    contentItems: [PuxKeyValueType]
  }
}

const EasySoftwareCaseStudyItem: FunctionComponent<EasySoftwareCaseStudyItemType> = (
  props
) => {
  return (
    <div className='CaseStudyItem'>
      <PuxLink to={`${getLocalizedUrl(props.path)}`} className='CaseStudyItem-image'>
        <PuxMediaSelector {...props.caseStudyImage} />
      </PuxLink>
      <div className='CaseStudyItem-text'>
        <PuxLink to={`${getLocalizedUrl(props.path)}`}>
          <h3 className='CaseStudyItem-title'>{props.displayText}</h3>
        </PuxLink>
        <div className='CaseStudyItem-parameters'>
          {props.caseStudyParameters &&
            props.caseStudyParameters.contentItems &&
            props.caseStudyParameters.contentItems.map((item) => (
              <div className='CaseStudyItem-parameter' key={item.contentItemId}>
                <div className='CaseStudyItem-label'>{item.displayText}:</div>
                <PuxWysiwyg inline content={item.keyValueContent} wysiwygId={props.contentItemId} />
              </div>
            ))}
        </div>
        <div className='CaseStudyItem-perex'>
          <PuxWysiwyg content={props.caseStudyPerex} wysiwygId={props.contentItemId} />
        </div>
        <PuxButton
          buttonType='Button--link'
          buttonSize='Button--large'
          buttonColor='Button--blue'
          buttonLink={{
            url: [props.path],
            internal: [true],
            text: [t`Redmine.CaseStudy.ReadMore`],
          }}
        />
      </div>
    </div>
  )
}

export default EasySoftwareCaseStudyItem

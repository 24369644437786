import "Shared/components/PuxButton/PuxButton.scss";

import React, { FunctionComponent } from "react";

import Layout from "../components/core/Layout";

const styleGuide: FunctionComponent<any> = (props) => {
  return (
    <div></div>
    // <Layout>
    //   <main>
    //     <div className="Container">
    //       <div>
    //         Size 45px | Line Height 55px | Color #334055 | Margin Top 60px |
    //         Margin Bottom 45px
    //       </div>
    //       <h1>H1 Ubuntu Medium</h1>
    //       <div>
    //         Size 40px | Line Height 45px | Color #334055 | Margin Top 60px |
    //         Margin Bottom 45px
    //       </div>
    //       <h2>H2 Ubuntu Medium</h2>
    //       <div>
    //         Size 30px | Line Height 35px | Color #334055 | Margin Top 40px |
    //         Margin Bottom 40px
    //       </div>
    //       <h3>H3 Ubuntu Medium</h3>
    //       <div>
    //         Size 24px | Line Height 30px | Color #334055 | Margin Top 40px |
    //         Margin Bottom 30px
    //       </div>
    //       <h4>H4 Ubuntu Medium</h4>
    //       <div>
    //         Size 19px | Line Height 25px | Color #334055 | Margin Top 30px |
    //         Margin Bottom 30px
    //       </div>
    //       <h5>H5 Ubuntu Medium</h5>
    //       <div>Size 20px | Line Height 30px | Color #334055</div>
    //       <div style={{ marginBottom: `50px` }} className="perex">
    //         Cras lacinia, urna in tincidunt bibendum, quam dui suscipit massa,
    //         ac consequat metus massa id nibh. Nulla tempus laoreet sapien a
    //         interdum. Ut et consectetur urna. Cras lacinia, urna in tincidunt
    //         bibendum, quam dui suscipit massa, ac consequat metus massa id nibh.
    //         Nulla tempus laoreet sapien a interdum. Ut et consectetur urna.
    //       </div>
    //       <div>Size 16px | Line Height 25px | Color #334055</div>
    //       <div style={{ marginBottom: `50px` }} className="text">
    //         Cras lacinia, urna in tincidunt bibendum, quam dui suscipit massa,
    //         ac consequat metus massa id nibh. Nulla tempus laoreet sapien a
    //         interdum. Ut et consectetur urna. Cras lacinia, urna in tincidunt
    //         bibendum, quam dui suscipit massa, ac consequat metus massa id nibh.
    //         Nulla tempus laoreet sapien a interdum. Ut et consectetur urna.
    //       </div>
    //       <div style={{ marginBottom: `50px` }} className="pux-wysiwyg">
    //         <ul>
    //           <li>Duis varius, dui eu bibendum lacinia.</li>
    //           <li>
    //             Mi at ultrices cursus. Vestibulum ante ipsum primis in faucibus
    //             orci luctus et ultrices posuere cubilia curae; In ac auctur
    //             felis.
    //           </li>
    //           <li>
    //             Quisque id auctor nulla, sed lobortis ipsum. Pellentesque erat
    //             orci, finibus nec odio vel, malesuada euismod turpis. Sed id sem
    //             lorem.
    //           </li>
    //           <li>
    //             Quisque consequat leo at mattis placerat. Pellentesque tincidunt
    //             magna nulla, non pellentesque mi viverra ac.
    //           </li>
    //         </ul>
    //       </div>
    //       <div style={{ marginBottom: `50px` }} className="pux-wysiwyg">
    //         <ol>
    //           <li>Duis varius, dui eu bibendum lacinia.</li>
    //           <li>
    //             Mi at ultrices cursus. Vestibulum ante ipsum primis in faucibus
    //             orci luctus et ultrices posuere cubilia curae; In ac auctur
    //             felis.
    //           </li>
    //           <li>
    //             Quisque id auctor nulla, sed lobortis ipsum. Pellentesque erat
    //             orci, finibus nec odio vel, malesuada euismod turpis. Sed id sem
    //             lorem.
    //           </li>
    //           <li>
    //             Quisque consequat leo at mattis placerat. Pellentesque tincidunt
    //             magna nulla, non pellentesque mi viverra ac.
    //           </li>
    //         </ol>
    //       </div>
    //       <div style={{ marginBottom: `50px` }} className="pux-wysiwyg">
    //         <table>
    //           <tbody>
    //             <tr>
    //               <th>Table link</th>
    //               <th>Table content</th>
    //               <th>Table content</th>
    //               <th>Table content</th>
    //             </tr>
    //             <tr>
    //               <td>
    //                 <a>199179001</a>
    //               </td>
    //               <td>15</td>
    //               <td>15</td>
    //               <td>15</td>
    //             </tr>
    //             <tr>
    //               <td>
    //                 <a>199179002</a>
    //               </td>
    //               <td>20</td>
    //               <td>20</td>
    //               <td>20</td>
    //             </tr>
    //             <tr>
    //               <td>
    //                 <a>199179003</a>
    //               </td>
    //               <td>25</td>
    //               <td>25</td>
    //               <td>25</td>
    //             </tr>
    //             <tr>
    //               <td>
    //                 <a>199179004</a>
    //               </td>
    //               <td>30</td>
    //               <td>30</td>
    //               <td>30</td>
    //             </tr>
    //             <tr>
    //               <td>
    //                 <a>199179005</a>
    //               </td>
    //               <td>35</td>
    //               <td>35</td>
    //               <td>35</td>
    //             </tr>
    //           </tbody>
    //         </table>
    //       </div>
    //       <div
    //         style={{
    //           display: `grid`,
    //           gridTemplateColumns: `repeat(4, 1fr)`,
    //           marginBottom: `50px`,
    //           padding: `25px`,
    //         }}
    //       >
    //         <div>
    //           <a
    //             href="#"
    //             className="Button Button--primary Button--large Button--yellow"
    //           >
    //             Primary Yellow Large
    //           </a>
    //         </div>
    //         <div>
    //           <a
    //             href="#"
    //             className="Button Button--primary Button--medium Button--yellow"
    //           >
    //             Primary Yellow Medium
    //           </a>
    //         </div>
    //         <div>
    //           <a
    //             href="#"
    //             className="Button Button--primary Button--small Button--yellow"
    //           >
    //             Primary Yellow Small
    //           </a>
    //         </div>
    //         <div>
    //           <a
    //             href="#"
    //             className="Button Button--primary Button--small Button--yellow is-disabled"
    //           >
    //             Primary Yellow Disabled
    //           </a>
    //         </div>
    //       </div>
    //       <div
    //         style={{
    //           display: `grid`,
    //           gridTemplateColumns: `repeat(4, 1fr)`,
    //           marginBottom: `50px`,
    //           padding: `25px`,
    //         }}
    //       >
    //         <div>
    //           <a
    //             href="#"
    //             className="Button Button--secondary Button--large Button--yellow"
    //           >
    //             Secondary Yellow Large
    //           </a>
    //         </div>
    //         <div>
    //           <a
    //             href="#"
    //             className="Button Button--secondary Button--medium Button--yellow"
    //           >
    //             Secondary Yellow Medium
    //           </a>
    //         </div>
    //         <div>
    //           <a
    //             href="#"
    //             className="Button Button--secondary Button--small Button--yellow"
    //           >
    //             Secondary Yellow Small
    //           </a>
    //         </div>
    //         <div>
    //           <a
    //             href="#"
    //             className="Button Button--secondary Button--small Button--yellow is-disabled"
    //           >
    //             Secondary Yellow Disabled
    //           </a>
    //         </div>
    //       </div>
    //       <div
    //         style={{
    //           display: `grid`,
    //           gridTemplateColumns: `repeat(4, 1fr)`,
    //           marginBottom: `50px`,
    //           padding: `25px`,
    //         }}
    //       >
    //         <div>
    //           <a
    //             href="#"
    //             className="Button Button--primary Button--large Button--blue"
    //           >
    //             Primary Blue Large
    //           </a>
    //         </div>
    //         <div>
    //           <a
    //             href="#"
    //             className="Button Button--primary Button--medium Button--blue"
    //           >
    //             Primary Blue Medium
    //           </a>
    //         </div>
    //         <div>
    //           <a
    //             href="#"
    //             className="Button Button--primary Button--small Button--blue"
    //           >
    //             Primary Blue Small
    //           </a>
    //         </div>
    //         <div>
    //           <a
    //             href="#"
    //             className="Button Button--primary Button--small Button--blue is-disabled"
    //           >
    //             Primary Blue Disabled
    //           </a>
    //         </div>
    //       </div>
    //       <div
    //         style={{
    //           display: `grid`,
    //           gridTemplateColumns: `repeat(4, 1fr)`,
    //           marginBottom: `50px`,
    //           padding: `25px`,
    //         }}
    //       >
    //         <div>
    //           <a
    //             href="#"
    //             className="Button Button--secondary Button--large Button--blue"
    //           >
    //             Secondary Blue Large
    //           </a>
    //         </div>
    //         <div>
    //           <a
    //             href="#"
    //             className="Button Button--secondary Button--medium Button--blue"
    //           >
    //             Secondary Blue Medium
    //           </a>
    //         </div>
    //         <div>
    //           <a
    //             href="#"
    //             className="Button Button--secondary Button--small Button--blue"
    //           >
    //             Secondary Blue Small
    //           </a>
    //         </div>
    //         <div>
    //           <a
    //             href="#"
    //             className="Button Button--secondary Button--small Button--blue is-disabled"
    //           >
    //             Secondary Blue Disabled
    //           </a>
    //         </div>
    //       </div>
    //       <div
    //         style={{
    //           display: `grid`,
    //           gridTemplateColumns: `repeat(4, 1fr)`,
    //           padding: `25px 25px 50px`,
    //           backgroundColor: `darkblue`,
    //         }}
    //       >
    //         <div>
    //           <a
    //             href="#"
    //             className="Button Button--primary Button--large Button--white"
    //           >
    //             Primary White Large
    //           </a>
    //         </div>
    //         <div>
    //           <a
    //             href="#"
    //             className="Button Button--primary Button--medium Button--white"
    //           >
    //             Primary White Medium
    //           </a>
    //         </div>
    //         <div>
    //           <a
    //             href="#"
    //             className="Button Button--primary Button--small Button--white"
    //           >
    //             Primary White Small
    //           </a>
    //         </div>
    //         <div>
    //           <a
    //             href="#"
    //             className="Button Button--primary Button--small Button--white is-disabled"
    //           >
    //             Primary White Disabled
    //           </a>
    //         </div>
    //       </div>
    //       <div
    //         style={{
    //           display: `grid`,
    //           gridTemplateColumns: `repeat(4, 1fr)`,
    //           padding: `25px`,
    //           margin: `0 0 50px`,
    //           backgroundColor: `darkblue`,
    //         }}
    //       >
    //         <div>
    //           <a
    //             href="#"
    //             className="Button Button--secondary Button--large Button--white"
    //           >
    //             Secondary White Large
    //           </a>
    //         </div>
    //         <div>
    //           <a
    //             href="#"
    //             className="Button Button--secondary Button--medium Button--white"
    //           >
    //             Secondary White Medium
    //           </a>
    //         </div>
    //         <div>
    //           <a
    //             href="#"
    //             className="Button Button--secondary Button--small Button--white"
    //           >
    //             Secondary White Small
    //           </a>
    //         </div>
    //         <div>
    //           <a
    //             href="#"
    //             className="Button Button--secondary Button--small Button--white is-disabled"
    //           >
    //             Secondary White Disabled
    //           </a>
    //         </div>
    //       </div>
    //       <div
    //         style={{
    //           display: `grid`,
    //           gridTemplateColumns: `repeat(4, 1fr)`,
    //           marginBottom: `50px`,
    //           padding: `25px`,
    //         }}
    //       >
    //         <div>
    //           <a
    //             href="#"
    //             className="Button Button--link Button--large Button--yellow"
    //           >
    //             Link Yellow Large
    //           </a>
    //         </div>
    //         <div>
    //           <a
    //             href="#"
    //             className="Button Button--link Button--medium Button--yellow"
    //           >
    //             Link Yellow Medium
    //           </a>
    //         </div>
    //         <div>
    //           <a
    //             href="#"
    //             className="Button Button--link Button--small Button--yellow"
    //           >
    //             Link Yellow Small
    //           </a>
    //         </div>
    //         <div>
    //           <a
    //             href="#"
    //             className="Button Button--link Button--small Button--yellow is-disabled"
    //           >
    //             Link Yellow Disabled
    //           </a>
    //         </div>
    //       </div>
    //       <div
    //         style={{
    //           display: `grid`,
    //           gridTemplateColumns: `repeat(4, 1fr)`,
    //           marginBottom: `50px`,
    //           padding: `25px`,
    //         }}
    //       >
    //         <div>
    //           <a
    //             href="#"
    //             className="Button Button--link Button--large Button--blue"
    //           >
    //             Link Blue Large
    //           </a>
    //         </div>
    //         <div>
    //           <a
    //             href="#"
    //             className="Button Button--link Button--medium Button--blue"
    //           >
    //             Link Blue Medium
    //           </a>
    //         </div>
    //         <div>
    //           <a
    //             href="#"
    //             className="Button Button--link Button--small Button--blue"
    //           >
    //             Link Blue Small
    //           </a>
    //         </div>
    //         <div>
    //           <a
    //             href="#"
    //             className="Button Button--link Button--small Button--blue is-disabled"
    //           >
    //             Link Blue Disabled
    //           </a>
    //         </div>
    //       </div>
    //       <div
    //         style={{
    //           display: `grid`,
    //           gridTemplateColumns: `repeat(4, 1fr)`,
    //           marginBottom: `50px`,
    //           backgroundColor: `darkblue`,
    //           padding: `25px`,
    //         }}
    //       >
    //         <div>
    //           <a
    //             href="#"
    //             className="Button Button--link Button--large Button--white"
    //           >
    //             Link White Large
    //           </a>
    //         </div>
    //         <div>
    //           <a
    //             href="#"
    //             className="Button Button--link Button--medium Button--white"
    //           >
    //             Link White Medium
    //           </a>
    //         </div>
    //         <div>
    //           <a
    //             href="#"
    //             className="Button Button--link Button--small Button--white"
    //           >
    //             Link White Small
    //           </a>
    //         </div>
    //         <div>
    //           <a
    //             href="#"
    //             className="Button Button--link Button--small Button--white is-disabled"
    //           >
    //             Link White Disabled
    //           </a>
    //         </div>
    //       </div>
    //       <div style={{ marginBottom: `50px` }}>
    //         <a
    //           href="#"
    //           className="pux-button pux-button-filled pux-button-primary pux-button-disabled"
    //         >
    //           Primary Button Disabled
    //         </a>
    //       </div>
    //       <div style={{ marginBottom: `50px` }}>
    //         <a
    //           href="#"
    //           className="pux-button pux-button-outlined pux-button-primary pux-button-disabled"
    //         >
    //           Secondary Button Disabled
    //         </a>
    //       </div>
    //       <div style={{ marginBottom: `50px` }}>
    //         <a
    //           href="#"
    //           className="pux-button pux-button-outlined pux-button-primary pux-button-disabled"
    //         >
    //           Secondary Button Disabled
    //         </a>
    //       </div>
    //       <div className="">
    //         <label>Text field</label>
    //         <input type="text" />
    //       </div>
    //       <div>
    //         <label>Textarea</label>
    //         <textarea />
    //       </div>
    //       <div className="">
    //         <input
    //           type="checkbox"
    //           id="subscribeNews"
    //           name="subscribe"
    //           value="newsletter"
    //         ></input>
    //         <label htmlFor="subscribeNews">Subscribe to newsletter?</label>
    //       </div>
    //       <input
    //         type="radio"
    //         id="html"
    //         name="fav_language"
    //         value="HTML"
    //       ></input>
    //       <label htmlFor="html">HTML</label>
    //       <input type="radio" id="css" name="fav_language" value="CSS"></input>
    //       <label htmlFor="css">CSS</label>
    //       <input
    //         type="radio"
    //         id="javascript"
    //         name="fav_language"
    //         value="JavaScript"
    //       ></input>
    //       <label htmlFor="javascript">JavaScript</label>
    //     </div>
    //     <div className="Section Section--light">
    //       <div className="Container">
    //         <div className="">
    //           <label>Text field</label>
    //           <input type="text" placeholder="Placeholder" />
    //         </div>
    //         <div>
    //           <label>Textarea</label>
    //           <textarea placeholder="Placeholder" />
    //         </div>
    //         <div className="error mauticform-has-error">
    //           <label>Text field</label>
    //           <input type="text" placeholder="Placeholder" />
    //           <span className="mauticform-errormsg">This is required.</span>
    //         </div>
    //         <div className="error mauticform-has-error">
    //           <label>Textarea</label>
    //           <textarea placeholder="Placeholder" />
    //           <span className="mauticform-errormsg">This is required.</span>
    //         </div>
    //         <div className="">
    //           <input
    //             disabled
    //             type="checkbox"
    //             id="subscribeNews"
    //             name="subscribe"
    //             value="newsletter"
    //           ></input>
    //           <label htmlFor="subscribeNews">
    //             Subscribe to Lorem Ipsum is <a href="">simply</a> dummy text of
    //             the printing and typesetting industry. Lorem Ipsum has been the
    //             industry's standard dummy text ever since the 1500s, when an
    //             unknown printer took a galley of type and scrambled it to make a
    //             type specimen book. It has survived not only five centuries, but
    //             also the leap into electronic typesetting, remaining essentially
    //             unchanged. It was popularised in the 1960s with the release of
    //             Letraset sheets containing Lorem Ipsum passages, and more
    //             recently with desktop publishing software like Aldus PageMaker
    //             including versions of Lorem Ipsum. Lorem Ipsum is simply dummy
    //             text of the printing and typesetting industry. Lorem Ipsum has
    //             been the industry's standard dummy text ever since the 1500s,
    //             when an unknown printer took a galley of type and scrambled it
    //             to make a type specimen book. It has survived not only five
    //             centuries, but also the leap into electronic typesetting,
    //             remaining essentially unchanged. It was popularised in the 1960s
    //             with the release of Letraset sheets containing Lorem Ipsum
    //             passages, and more recently with desktop publishing software
    //             like Aldus PageMaker including versions of Lorem Ipsum. Lorem
    //             Ipsum is simply dummy text of the printing and typesetting
    //             industry. Lorem Ipsum has been the industry's standard dummy
    //             text ever since the 1500s, when an unknown printer took a galley
    //             of type and scrambled it to make a type specimen book. It has
    //             survived not only five centuries, but also the leap into
    //             electronic typesetting, remaining essentially unchanged. It was
    //             popularised in the 1960s with the release of Letraset sheets
    //             containing Lorem Ipsum passages, and more recently with desktop
    //             publishing software like Aldus PageMaker including versions of
    //             Lorem Ipsum. ?
    //           </label>
    //         </div>
    //         <input
    //           type="radio"
    //           id="html2"
    //           name="fav_language"
    //           value="HTML"
    //         ></input>
    //         <label htmlFor="html2">HTML</label>
    //         <input
    //           type="radio"
    //           id="css2"
    //           name="fav_language"
    //           value="CSS"
    //         ></input>
    //         <label htmlFor="css2">CSS</label>
    //         <input
    //           type="radio"
    //           id="javascript2"
    //           name="fav_language"
    //           value="JavaScript"
    //         ></input>
    //         <label htmlFor="javascript2">JavaScript</label>
    //       </div>
    //     </div>
    //     <div
    //       id="mauticform_interestedinredminesupport_submit"
    //       className="mauticform-row mauticform-button-wrapper mauticform-field-7"
    //     >
    //       <button
    //         type="submit"
    //         name="mauticform[submit]"
    //         id="mauticform_input_interestedinredminesupport_submit"
    //         value=""
    //         className="mauticform-button pux-button pux-button-filled pux-button-primary"
    //       >
    //         Submit
    //       </button>
    //     </div>
    //   </main>
    // </Layout>
  );
};

export default styleGuide;

import React, { FunctionComponent, useState } from 'react'

export interface TestimonialIconType {
  icon: 'TestimonialIcon'
  options?: TestimonialIconPropsType
}

interface TestimonialIconPropsType {
  className?: string
  color: TestimonialIconStateType
  hoverColor: TestimonialIconStateType
}

interface TestimonialIconStateType {
  colorPrimary: string
}

const TestimonialIcon: FunctionComponent<TestimonialIconPropsType> = (
  props
) => {
  const defaultTheme = props.color
  const hoverTheme = props.hoverColor
  const [colorTheme, setColorTheme] = useState<TestimonialIconStateType>(
    defaultTheme
  )

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 44.2 39.86'
      className={props.className?.toLowerCase()}
      onMouseEnter={() => setColorTheme(hoverTheme)}
      onMouseLeave={() => setColorTheme(defaultTheme)}
    >
      <path
        stroke={colorTheme.colorPrimary}
        fill='none'
        strokeMiterlimit='10'
        strokeWidth='1.2px'
        d='M481.63,1418.28c11.87,0,21.5,7.84,21.5,17.5a15.68,15.68,0,0,1-5.22,11.41,26.34,26.34,0,0,0,1.82,8.37,1,1,0,0,1-1.16,1.35,21,21,0,0,1-9.57-4.71,25.81,25.81,0,0,1-7.38,1.08c-11.87,0-21.5-7.83-21.5-17.5S469.76,1418.28,481.63,1418.28Z'
        transform='translate(-459.53 -1417.68)'
      />
      <path
        stroke={colorTheme.colorPrimary}
        fill='none'
        strokeMiterlimit='10'
        strokeWidth='1.2px'
        d='M474.13,1445.28c4.75-2.54,6-8,6-8v-8h-10v8h5a7.89,7.89,0,0,1-3,6'
        transform='translate(-459.53 -1417.68)'
      />
      <path
        stroke={colorTheme.colorPrimary}
        fill='none'
        strokeMiterlimit='10'
        strokeWidth='1.2px'
        d='M488.13,1445.28c4.75-2.54,6-8,6-8v-8h-10v8h5a7.89,7.89,0,0,1-3,6'
        transform='translate(-459.53 -1417.68)'
      />
    </svg>
  )
}

export default TestimonialIcon

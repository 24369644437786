import React, { FunctionComponent } from 'react'

import useWindowSize from '../../helper/useWindowSize'

export interface MediaVideoFile {
  paths: [string],
  type: [string]
}

export interface MediaFile {
  paths: [string]
}

export interface PuxBackgroundVideoSelectorType {
  backgroundVideoFileWEBM: MediaVideoFile,
  backgroundVideoFileMP4: MediaVideoFile,
  backgroundVideoPlaceholder: MediaFile,
  backgroundVideoLoop: boolean,
  backgroundVideoAutoplay: boolean,
  backgroundVideoControls: boolean,
  backgroundVideoShowOnMobile: boolean
}

const EasySoftwareBackgroundVideo: FunctionComponent<PuxBackgroundVideoSelectorType> = (
  props
) => {
  const isEmpty = ((props.backgroundVideoFileMP4?.paths[0] === ``) || (props.backgroundVideoFileWEBM?.paths[0] === ``) ? true : false)
  const size = useWindowSize();

  if (!isEmpty) {
    return (
      <>
        {(props.backgroundVideoShowOnMobile === false) && (size && size.width && size.width < 768) ?
          <img src={`${process.env.GATSBY_ORCHARD_API_URL}/media/${props.backgroundVideoPlaceholder?.paths[0]}`} alt="Placeholder image" />
          : ``
        }
        {(props.backgroundVideoShowOnMobile === true) || (size && size.width && size.width >= 768) ?
          <video
            playsInline={true}
            autoPlay={props.backgroundVideoAutoplay}
            controls={props.backgroundVideoControls}
            loop={props.backgroundVideoLoop}
            muted
            width="100%"
            height="100%"
          >
            {props.backgroundVideoFileWEBM && props.backgroundVideoFileWEBM.paths[0] ?
              <source
                src={`${process.env.GATSBY_ORCHARD_API_URL}/media/${props.backgroundVideoFileWEBM?.paths[0]}`}
                type={props.backgroundVideoFileWEBM.type[0]}
              ></source>
              : null}
            {props.backgroundVideoFileMP4 && props.backgroundVideoFileMP4.paths[0] ?
              <source
                src={`${process.env.GATSBY_ORCHARD_API_URL}/media/${props.backgroundVideoFileMP4?.paths[0]}`}
                type={`video/mp4`}
              ></source>
              : null}
            <img src={`${process.env.GATSBY_ORCHARD_API_URL}/media/${props.backgroundVideoPlaceholder?.paths[0]}`} title="Your browser does not support the <video> tag" alt="Your browser does not support the <video> tag" />
          </video>
          : ``
        }
      </>
    )
  } else {
    return null
  }
}

export default EasySoftwareBackgroundVideo
